<template>
  <AdminLayout>
    <snackbar   :snackbar="snackbar" :snackbarColor="snackbarColor" :snackbarMessage="snackbarMessage" @close="snackbar = false"></snackbar>

    <div class="d-flex justify-space-between mt-5 mb-12" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > 
        {{ $t("Ads") }}
      </p>
    </div>
    <v-container>
      <response class="mb-3" v-if="res" :res="res"></response>
      <error class="mb-3" v-if="error" :error="error"></error>
      <v-row class="d-flex flex-column align-center justify-center">
        <v-col  cols="10" >
          <div class="d-flex  justify-end ">
            <label for="fileInput" class="mr-4" style="cursor: pointer;" @click="triggerFileInput">
              <v-btn
                type="submit"
                class="px-9 mb-4"
                color="blue"
                variant="tonal"
                :text="$t('ADD')"
                :loading="loading"
                rounded
                >
              </v-btn>
            </label>
            <!-- Hidden file input -->
            <input type="file" id="fileInput" style="display: none;" @change="handleImageUpload">
          </div>
        </v-col>
        <!-- images-------- -->
        <v-col cols="10" v-for="(image, index) in images" :key="index">
          <v-card class="image-card  d-flex flex-column" height="fit-content" elevation="1">
            <img :src="image.image" height="200px" cover>
          <div class="buttons d-flex flex-row align-content-end justify-end mb-5  mt-2 mx-3" style="gap: 10px;">
            <v-btn :loading="deleteloading" rounded :text="$t('delete')" color="red" variant="outlined" @click="deleteImage(image.id)"></v-btn>
            <label for="fileInputReplace" class="mr-4" style="cursor: pointer;" @click="triggerFileInputReplace (image.id)">
            <v-btn :loading="replceloading" rounded :text="$t('replace')" color="black"></v-btn>
            </label>
              <input type="file" id="fileInputReplace" style="display: none;" @change="replaceImage($event)">
          </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </AdminLayout>
</template>

<script>
import AdminLayout from '@/components/global/adminLayout.vue'
import response from '@/components/global/response.vue'
import error from '@/components/global/errorView'
import snackbar from '@/components/global/snackBar.vue'

import axios from 'axios'

export default {
  components: { AdminLayout, response, error,snackbar },
  data() {
    return {
      error: '',
      snackbar: false,
      snackbarColor: 'success',
      snackbarMessage: '',
      replacedID: null,
      res: '',
      imageUrl: '',
      loading: false,
      deleteloading: false,
      replceloading: false,
      imageFile: null,
      images: [],
    }
  },
  created() {
    this.getImages();
  },
  methods: {
    async getImages() {
      this.loading=true;
      const res = await axios.get(`/banners?page=1&timestamp=${Date.now()}`);
      this.images = res.data.data;
      this.loading=false;

    },
    async deleteImage(id) {
      this.deleteloading = true;
      const res = await axios.delete(`/banners/${id}`);
      this.res = res.data.data.success;
      this.deleteloading = false;
      //refresh the page
      await this.getImages();
    },
    handleImageUpload(event) {
      const file = event.target.files[0]; // Get the first file from the event
      if (file) {
        this.imageUrl = URL.createObjectURL(file);
        this.imageFile = file;
      }
      this.saveimage();
    },
    triggerFileInput() {
      // Programmatically trigger click event on file input
      document.getElementById('fileInput').click();
    },
    triggerFileInputReplace(id) {
      this.replacedID=id;
      document.getElementById('fileInputReplace').click();
    },
    async saveimage() {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append('image', this.imageFile);
        await axios.post('/banners', formData);
        // Show snackbar on successful image addition
        this.snackbarMessage = 'Image added successfully';
        this.snackbarColor = 'success';
        this.snackbar = true;

        //refresh the page
        await this.getImages();
      } catch (e) {
        console.error('Error:', e);
        this.error = e;
      } finally {
        this.loading = false;
        this.imageUrl = '';
        this.imageFile = null;
      }
    },
    async replaceImage(event) {
      const file = event.target.files[0]; // Get the first file from the event
      if (file) {
        this.imageUrl = URL.createObjectURL(file);
        this.imageFile = file;
      }
      try {
        this.replceloading = true;
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('image', this.imageFile);

        const id = this.replacedID ;
        await axios.post(`/banners/${id}`, formData );
        // Show snackbar on successful image replacement
        this.snackbarMessage = 'Image replaced successfully '+id;
        this.snackbarColor = 'success';
        this.snackbar = true;

        //refresh the page
        await this.getImages();
      } catch (e) {
        console.error('Error:', e);
        this.error = e;
      } finally {
        this.replceloading = false;
        this.imageUrl = '';
        this.imageFile = null;
      }
    },
  },
}
</script>


<style scoped>
.image-card {
  height: 100px;
}

.image-container {
  height: 100%;
  width: 100% !important; 
  background-size: cover;
  background-position: center;
}
.image-card {
  overflow: hidden; /* Ensure that the image does not overflow the card */
  position: relative; /* Position the button div relative to the card */
}

.image-card img {
  width: 100%; 
  height: auto; 
  object-fit: cover; 
  position: relative; 
}

@media (max-width: 768px) {
  .image-card {
    height: 200px; 
  }
}
</style>
