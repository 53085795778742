<template>
  <v-container class="mx-auto pa-md-12 pa-sm-6" fluid>
    <v-responsive
      class="text-center mx-auto mb-6 mb-md-12"
      max-width="700"
      >
      <router-link to="/">
        <img src="@/assets/images/EboomTitle.png" class="responsive-img" />
      </router-link>
      <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title" style="font-weight: 200;">
        {{$t('Servises')}}
      </h1>
      <p class="text-subtitle-1 text-medium-emphasis">
        {{ $t ('Welcome to Eboom where every click brings joy and every purchase is satisfaction guaranteed Lets shop smarter') }}
      </p>
    </v-responsive>
    <v-lazy
      :min-height="200"
      :options="{'threshold':0.5}"
      transition="fade-transition"
      >
      <v-row>
        <v-col
          cols="12" 
          md="4" 
          v-for="(sheet, index) in sheets" 
          :key="index"
          >
          <v-sheet 
            border 
            class="mx-auto pa-2 my-4 h-100" 
            max-width="400" 
            rounded="xl"
            >
            <v-col>
              <v-row class="h-100 flex-column d-flex align-center justify-center pa-4">
                <v-card border class="flex-column d-flex align-center justify-center pa-4 w-100" color="blue" rounded="xl" variant="tonal">
                  <div class="text-h5 pa-8 text-black font-weight-bold text-center justify-center">
                    {{ $t(sheet.title) }}
                  </div>
                </v-card>
              </v-row>
              <v-row class="pa-6">
                <div :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
                  <p class="text-medium-emphasis mb-4 ">
                    {{ $t(sheet.description) }}
                  </p>
                </div>
                <!--
                <div class="align-center justify-center">
                  <v-btn class="mb-5 text-none" color="blue" max-height="36" max-width="256" rounded="xl" text="Read More" variant="tonal" width="100%" />
                </div>
                -->
              </v-row>
            </v-col>
          </v-sheet>
        </v-col>
      </v-row>
    </v-lazy>
  </v-container>
</template>
  
<style scoped>
.equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

</style>

  <script>
export default {
  name: 'ServiceView',
  data() {
    return {
      // @description Array of sheets to display
      sheets: [
        {
          title: "Nationwide Product Delivery",
          description: "Join us and have all your products delivered to customers across the region hassle-free."
        },
        {
          title: "Monitor your store's growth",
          description: "Keep track of your store's progress by analyzing sales trends and identifying your best-selling products"
        },
        {
          title: "Product listing in our app",
          description: "Partner with our app to showcase your products directly to customers, increasing visibility and driving additional sales.",          
        },
        {
          title: "Rapid Profit increase",
          description: "Experience a significant boost in profits in no time as our team works efficiently to maximize returns for your store.",          
        },
        {
          title: "None Yet",
          description: "No Description Yet",          
        },
        {
          title: "None Yet",
          description: "No Description Yet",          
        },
      ]
    }
  }
}
</script>         