<template>
  <AdminLayout>
    <v-container class="main">

      <v-row>
        <v-col cols="12" md="9" sm="12" > 
          
            <div class="d-flex justify-space-between mt-5  mr-3">
              
              <p class=" align-self-start text-h4 font-weight-bold mt-3 ml-3">Product info</p>
              <v-spacer></v-spacer>
              <v-col class="align-self-end" cols="3">

              </v-col>
            </div>
          
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12"> 
          <v-card>
            <div class="d-flex flex-row bg-white text-center" style="width: 96%;">

              <div class="align-self-start flex-grow-1 mt-2 " style="color: black;font-weight: bold;">
                Product ID #{{ $route.params.id }}
              </div>
              <v-spacer></v-spacer>
              <div class="align-self-end">
                <v-btn class="bg-black mt-1 mb-1" :to="{ name: 'edit', params: { id: $route.params.id }  }" height="30" rounded>modify</v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
        <!-- lift side -->
      <v-row class="mt-3">
        <v-col cols="12" md="3" class="d-flex justify-center mt-3 " style="background-color: #F5F6FA;">
          <div class="d-flex flex-column" style="gap: 10px;margin-top:-10px;">

        <!-- product photo and info  -->
        <v-card class="d-flex flex-column " height="fit-content" width="250" color="white">
          <v-col class=" d-flex flex-row">
            <v-card>
              <img :src="data.images" height="200" width="200">
            </v-card>
          </v-col>

<v-col class=" d-flex flex-row justify-center align-center "> <span class="text-caption text-center">{{ data.name_en }}</span> </v-col>
<v-col class=" d-flex flex-row justify-center align-center "> <span class="text-caption text-center">{{ data.name_ar }}</span> </v-col>
<v-col class=" d-flex flex-row justify-center align-center "><h1>{{ data.price }} JOD</h1></v-col>


            </v-card>

      <!-- status -------------------------------------------- -->
      <v-card class="d-flex flex-column "  height="fit-content" width="250" color="white">
        <h2 class="text-h6 d-flex justify-center align-center">Status</h2>
        <v-col class="d-flex flex-column " >
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" ><span class=" font-weight-bold" >Price: </span> <v-spacer></v-spacer> <span>{{ data.price }}</span></v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Total Rating: </span> <v-spacer></v-spacer> <span>{{ data.total_rating }}</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Category: </span> <v-spacer></v-spacer> <span>{{ catigory.name }}</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Sub catigory: </span> <v-spacer></v-spacer> <span>{{ catigory.sub_categories }}</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Date Added: </span> <v-spacer></v-spacer> <span>{{ data.created_at }}</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Card type: </span> <v-spacer></v-spacer> <span>{{ data.label }}</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Discount: </span> <v-spacer></v-spacer> <span>{{ data.discount }}</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Weight: </span> <v-spacer></v-spacer> <span>{{ data.shipping_weight }}</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Color: </span> <v-spacer></v-spacer> <span :style="{ 'background-color': color}"> <div style="height: 25px;width: 25px; "></div> </span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Size: </span> <v-spacer></v-spacer> <span>{{ size }}</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Visible To Costomer: </span> <v-spacer></v-spacer> <span v-if="data.is_visiable=== '1'"> yes</span> <span v-else>no</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Out of stuck: </span> <v-spacer></v-spacer> <span v-if="data.out_of_stock=== '1'"> yes</span> <span v-else>no</span></v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">Subscribe: </span> <v-spacer></v-spacer> <span >{{ data.subscribe }}</span> </v-card>
          <v-card class=" d-flex flex-row align-content-space-between" style="border-bottom: gray 1px solid;" > <span class=" font-weight-bold">sku: </span> <v-spacer></v-spacer> <span>{{ data.sku }}</span> </v-card>
        </v-col>
            </v-card>
          </div>
        </v-col>
        <!-- right side , list of sellers on the order -->
        <v-col cols="12" md="9" class="mt-3 bg-white">
          <v-row>
            <v-col cols="12"> 
              <v-card>
  <v-row class="d-flex flex-row justify-space-between align-center">
    <v-col cols="auto"> 
      <div class="ml-9 mt-4 mb-3" style="width: 100px;">
        <v-avatar style="height: fit-content; width: fit-content;">
            <img height="70px" width="80px"  :src="seller.image" cover >
          </v-avatar>
      </div>
    </v-col>
    <v-col cols="auto"> 
      <v-card-title>
        {{ seller.name }}
      </v-card-title>
      <v-card-subtitle>
        {{ seller.id }}
      </v-card-subtitle>
    </v-col>
    <v-col cols="auto">
      <v-btn  @click="goTOStore(seller.id)" variant="text">View</v-btn>
    </v-col>
  </v-row>
              </v-card>
            </v-col>
          </v-row>
<!-- three cards============================ -->
          <v-row class="cards ml-1" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <v-col  v-for="(card, index) in cardData" :key="index" cols="12" sm="6" md="5" lg="3">
        <v-card class="card" height="150" width="fit-content ">
          <div class="d-flex align-center justify-space-between">
            <div class="mb-4">
              <v-card-subtitle class="mt-4">{{ card.name }}</v-card-subtitle>
              <v-card-title class="text-h4 mt-1">{{ card.number }}</v-card-title>
            </div>
            <v-avatar size="50" class="bg-amber-accent-1 mt-6">
              <img alt="Icon" :src="icons[index % icons.length].url">
            </v-avatar>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center justify-space-between mt-2">
            <div v-if="card.is_up">
              <v-icon><img :src="stateUp.url"/></v-icon>
              <span>{{ stateUp.text }}</span>
            </div>
            <div v-else>
              <v-icon><img :src="stateDown.url"/></v-icon>
              <span>{{ stateDown.text }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
          
        </v-col>
      </v-row>
    </v-container>
  </AdminLayout>
</template>
<script>
import AdminLayout from '@/components/global/adminLayout.vue'
import axios from 'axios';


export default {
  components: { AdminLayout   },
  data(){
    return{
      data:[],
      variations:[],
      color : '',
      size : '',
      catigory:[],
      seller:[],
      cardData: [],
      icons: [
        { url: require('@/assets/icons/Iconchart.png'), case: 'chart' },
        { url: require('@/assets/icons/Icon.png'), case: 'box' },
        { url: require('@/assets/icons/Iconchart.png'), case: 'chart' },
      ],
      stateUp: { url: require('@/assets/icons/up.png'), text: 'Up from last week' },
      stateDown: { url: require('@/assets/icons/down.png'), text: 'Down from last week' },



    }
  },
created(){
  this.getInfo();
},
methods:{
  async getInfo() {
    const id = this.$route.params.id;
    const res = await axios.get(`/products/${id}`);
    this.data = res.data.data.product;
    this.variations = this.data.variations;
    this.catigory = res.data.data.product.category;
    this.seller = res.data.data.product.seller;

    this.cardData = [
        { name: 'Total sales', number: this.data.total_selling, is_up: true },
        { name: 'Total Orders', number: this.data.total_amount_selling, is_up: false },
        { name: 'Costomer views', number: this.data.views, is_up: true },
    ];

    // Initialize size and color variables
    this.size = '';
    this.color = '';

    // Iterate over variations array
    for (const variation of this.variations) {
        // Find attribute with name "المقاس" for size
        const sizeAttribute = variation.attribute_values.find(attr => attr.atrribute.name === 'المقاس');
        if (sizeAttribute) {
            this.size = sizeAttribute.name;
        }

        // Find attribute with name "لون" for color
        const colorAttribute = variation.attribute_values.find(attr => attr.atrribute.name === 'لون');
        if (colorAttribute) {
            this.color = colorAttribute.code;
        }

        // Check if both size and color are found
        if (this.size && this.color) {
            // If both size and color are found, exit the loop
            break;
        }
    }
}
,
goTOStore(id){
  this.$router.push({ name: 'storeInfo', params: { id: id } });
},


}
}
</script>

<style scoped>
.main {
  background-color: #F5F6FA;
}
.rounded1 {
  border-radius: 10px;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
</style>
