<template>

  <AdminLayout>
    <div class="d-flex justify-space-between mt-5 mb-12" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > 
        {{ $t("Plans") }}
      </p>
    </div>
      <v-col>
      <error v-if="error"  :error="error"></error>
    </v-col>
    <!-- -------- table -------- -->
    <v-col> 
      <v-card :loading="deleteloading">
        <v-table>
            <thead style="background-color: #F5F6FA;">
              <tr>
                <th> {{ $t("ID") }}</th>
                <th> {{ $t("Name") }}</th>
                <th> {{ $t("Name english") }}</th>
                <th> {{ $t("Name arabic") }}</th>
                <th> {{ $t("products limit") }}</th>
                <th> {{ $t("Actions") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="plan in allPlans" :key="plan.id" >
                <td>{{ plan.id }}</td>
                <td>{{ plan.name }}</td>
                <td>{{ plan.name_en  }}</td>
                <td>{{ plan.name_ar }}</td>
                <td>{{ plan.products_limit }}</td>
                <td class=" d-flex flex-row">
                  <v-btn v-if="plan.id !==1" @click="openDeleteDialog(plan.id)">
                    <v-icon color="red" >mdi-delete</v-icon>
                  </v-btn>
                  <v-btn @click="getID(plan.id)">
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
      </v-card>
    </v-col>
    <v-col>
      <div class="d-flex justify-end ">
        <v-btn
          type="submit"
          class="mb-8"
          color="blue"
          size="large"
          variant="tonal"
          :text="$t('ADD')"
          @click="addDealog=true"
          block
          rounded
          >
        </v-btn>
      </div>
    </v-col>
  </AdminLayout>



<!-- ================== Delete dealog ================== -->
  <v-dialog v-model="deleteDialog" max-width="500">
    <v-card :loading="loading" rounded="lg">
      <v-card-title class="text-center font-weight-bold">ARE YOU SURE?</v-card-title>
      <div class="d-flex flex-row align-center justify-center" style="gap: 25px;">
        <v-btn type="submit" class="px-9 mb-4" color="red" variant="tonal" :text="$t('YES')" :loading="loading" @click="confirmDelete()" rounded>
        </v-btn>
        <v-btn type="submit" class="px-9 mb-4" color="blue" variant="tonal" :text="$t('NO')" :loading="loading" @click="deleteDialog = false" rounded>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
<!-- ================== add dealog ================== -->
  <v-dialog v-model="addDealog" max-width="500">
      <v-card :loading="loading">
        <v-card-title class="headline text-center"> {{ $t("ADD PlAN") }}</v-card-title>
        <v-card-actions>
          <v-container class=" d-flex flex-column">
            <v-col><v-text-field v-model="name_en"  :label="$t('Name')"  required></v-text-field></v-col>
            <v-col><v-text-field v-model="name_ar"  :label="$t('Name arabic')"  required></v-text-field></v-col>
            <v-col><v-text-field v-model="products_limit"  :label="$t('products limit')"  :rules="[numericRule]"  required></v-text-field></v-col>
            <v-spacer></v-spacer>
            <div class=" d-flex flex-row">
              <v-btn color="primary darken-1" :loading="loading" text @click="addConfirmed"> {{ $t("SAVE") }}</v-btn>
              <v-btn color=" red darken-1" text @click="addCancelled"> {{ $t("IGNORE") }}</v-btn>
            </div>
          </v-container>

        </v-card-actions>
      </v-card>
    </v-dialog>
<!-- ================== edit dealog ================== -->
  <v-dialog v-model="editDealog" max-width="500">
      <v-card :loading="editloading">
        <v-card-title class="headline text-center"> {{ $t("EDIT PlAN") }}</v-card-title>
        <v-card-actions>
          <v-container class=" d-flex flex-column">
            <v-col><v-text-field v-model="name_en"  :label="$t('Name')" required></v-text-field></v-col>
            <v-col><v-text-field v-model="name_ar"  :label="$t('Name arabic')" required></v-text-field></v-col>
            <v-col><v-text-field v-model="products_limit"    :label="$t('products limit')" :rules="[numericRule]" required></v-text-field></v-col>
            <v-spacer></v-spacer>
            <div class=" d-flex flex-row">
              <v-btn color="primary darken-1" :loading="loading" text @click="editPlan"> {{ $t("SAVE") }}</v-btn>
              <v-btn color=" red darken-1" text @click="addCancelled"> {{ $t("IGNORE") }}</v-btn>
            </div>
          </v-container>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>





  <script>
  import AdminLayout from '@/components/global/adminLayout.vue'
  import error from "@/components/global/errorView.vue";
import axios from 'axios';
  export default {
    components:{AdminLayout,error},
    data() {
      return {
        numericRule: v => /^\d+$/.test(v) || 'Only numeric characters are allowed',

        loading:false,
        editloading:false,
        deleteloading:false,
        addDealog:false,
        editDealog:false,
        deleteDialog: false,
        products_limit:null,
        name_en:'',
        name_ar:'',
        allPlans:[],
        editID:'',
        deleteID: null,
        error:'',
      }
    },
    created() {
      this.getPlans();
    },
    methods:{
      getID(id){
        this.editID=id;
        this.editDealog= true;
      },
    async  editPlan(){
        
        const id = this.editID;
  try{
    this.editloading=true;
    await axios.post(`/packages/${id}`, {
      _method: 'PUT',
      name_en: this.name_en,
      name_ar: this.name_ar,
      products_limit: this.products_limit
    });
    await this.getPlans();
  }catch (e) {
    console.error('Error logging in:', e);
this.error=e;

  }finally{
    this.editDealog=false;
    this.editloading=false;
    this.products_limit=null;
        this.name_en='';
        this.name_ar='';
  }
      },


async getPlans(){
  this.deleteloading=true;
const res = await axios.get(`/packages?page=1`);
this.allPlans = res.data.data;
this.deleteloading=false;

},
addCancelled(){
        this.products_limit=null;
        this.name_en='';
        this.name_ar='';
        this.addDealog=false;
        this.editDealog=false;
},
async addConfirmed(){
  this.loading=true;
  try{
    await axios.post('/packages', {
      name_en: this.name_en,
      name_ar: this.name_ar,
      products_limit: this.products_limit
    });
    await this.getPlans();
  }catch (e) {
    
    console.error('Error logging in:', e);
this.error=e;

  }finally{
    this.loading=false;
    this.addDealog=false;
    this.products_limit=null;
        this.name_en='';
        this.name_ar='';
  }
},

openDeleteDialog(id) {
      this.deleteID = id;
      this.deleteDialog = true;
    },
    async confirmDelete() {
      this.deleteLoading = true;
      try {
        await axios.delete(`/packages/${this.deleteID}`);
        await this.getPlans();
      } catch (e) {
        console.error('Error deleting plan:', e);
        this.error = e;
      } finally {
        this.deleteLoading = false;
        this.deleteDialog = false;
        this.deleteID = null;
      }
    },
async deletePlan(id){
  this.deleteloading=true;
  try{
    axios.delete(`/packages/${id}`)
    
  }

    catch(e) {
      console.error('Error deleting seller:', e);
      this.error = error; 
    }finally{
      this.deleteloading=false;
      await this.getPlans();
    }
},
    },
  }
  </script>