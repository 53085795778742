<template>
  <main-Layout class="Layout ml-6" style="width: 100%;">

    

    <div class="ml-2 mt-2 mr-0 pa-6 pr-16 d-flex flex-column justify-space-between" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'"
      style="height: fit-content;">
      <v-row>
        <div class="top-text mt-4 mb-5">
          {{ $t("edit your profile, upload image for your store and information that you want to appear") }}
        </div>
      </v-row>

      <v-row class="d-flex justify-center">
  <v-card class="myCard" sm="10" md="8" lg="6">
    <div class="card-header">
      <span class="inline-id">ID: {{ id }}</span>
    </div>
    <v-row class="card-content">
      <v-col cols="5" sm="5" class="image-column">
        <img v-if="!imageUrl" src="@/assets/images/account.png" class="store-image" alt="Store Image">
        <img v-else :src="imageUrl" class="store-image" alt="Store Image">
        <v-btn icon @click="triggerFileInput" class="upload-button">
          <v-icon class="pencil-icon">mdi-pencil</v-icon>
          <input type="file" ref="fileInput" style="display: none;" @change="handleImageUpload" />
        </v-btn>
      </v-col>
      <v-col cols="7" sm="7" class="info-column">
        <div class="info-row">
          <span>Shop name:</span><span>{{ shop_name }}</span>
        </div>
        <div class="info-row">
          <span>Email:</span><span>{{ email }}</span>
        </div>
        <div class="info-row">
          <span>Phone number:</span><span>{{ phone }}</span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</v-row>

      <v-row> 
        <h4 class="text-h5 font-weight-bold mt-4">your store informaion :</h4>
      </v-row>
<v-row>
  <h5 class="text-h6 font-weight-bold mt-7">Discription : </h5>
  
</v-row>
<v-row class="mt-5">
  <v-textarea v-model="description" hint="inter a discroiption to your store"></v-textarea>
</v-row>


<v-row>
    <v-col cols="6">
        <v-text-field v-model="website" label="website Store (optinal)"></v-text-field>
    </v-col>
    <v-col cols="6">
        <v-select v-model="city" :items="cities" 
            label="city" ></v-select>
    </v-col>
</v-row>
<v-row>
  <v-col class="map" cols="6">
    <div>
    <GoogleMap :api-key="apikey"
    style="width: 100%; height: 500px" :center="center"
      :zoom="13" @click="handleMapClick">
      <Marker :options="{ position: selectedLocation, draggable: true }" />
    </GoogleMap>
    <!-- <p>Latitude: {{ selectedLocation.lat }}</p>
    <p>Longitude: {{ selectedLocation.lng }}</p> -->
  </div>
  </v-col>
  <v-col cols="6">
    <v-row>   <v-text-field class="mt-3" v-model="nearby"  label="near by (optional)" ></v-text-field></v-row>
    <v-row>   <v-text-field class="mt-3" v-model="address"  label="street address" ></v-text-field></v-row>
    
  </v-col>
</v-row>
<v-row>
    <v-col cols="6">
        <v-text-field v-model="email2" label="Email2 (optional)"></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field v-model="phone2" label="phone number 2 (optional)"></v-text-field>

    </v-col>
</v-row>
<v-col class="mt-5"> <error v-if="error"  :error="error"></error> </v-col>
<v-row class=" d-flex justify-center align-center ">
  <v-btn width="200" rounded :loading="loading" class="bg-black px-8" @click="submit()">save</v-btn></v-row>
    </div>
    <snackBar :snackbar="snackbar" :snackbarMessage="snackbarMessage" :snackbarColor="snackbarColor"></snackBar>
  </main-Layout>
</template> 
<script>
import MainLayout from '@/components/global/mainLayout.vue'
import axios from 'axios';
import { ref } from 'vue';
import { GoogleMap, Marker } from 'vue3-google-map';
import error from '@/components/global/errorView.vue'
import snackBar from '@/components/global/snackBar.vue'

export default {
  components: { MainLayout, GoogleMap, Marker,error,snackBar },
  data() {
    return {
      apikey:process.env.VUE_APP_API_KEY_GOOGLe_MAPs,

      id:localStorage.getItem('id'),
      center: { lat: 31.963158, lng: 35.930359 },
      selectedLocation: ref({ lat: 0, lng: 0 }),
      showImage: true,
      uploading: true,
      loading: false,
      error: false,
      snackbar: false,
      snackbarMessage: 'updated successfully',
      snackbarColor: 'green',
      email: localStorage.getItem('email'),
      shop_name: localStorage.getItem('store_name'),
      imageFile: null,
      imageUrl: '',
      description: '',
      email2: '',
      phone: localStorage.getItem('phone'),
      phone2: '',
      website: '',
      address: '',
      nearby: '',
      city: '',
      cities: [
        'Amman',
        'Zarqa',
        'Irbid',
        'Jerash',
        'Madaba',
        'Balqa',
        'Mafraq',
        'Karak',
        'Tafilah',
        'Ma\'an',
        'Aqaba'
      ],
    }
  },
  methods: {
    handleMapClick(event) {
      this.selectedLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },


    async submit() {
          this.loading = true
          try{
            {
              const formData = new FormData();
              formData.append('_method', 'PUT');
              formData.append('description', this.description);
              formData.append('website', this.website);
              formData.append('address[country]', 'Jordan');
              formData.append('address[region]', this.city);
              formData.append('address[street]', this.address);
              formData.append('alter_phone', this.phone2);
              formData.append('alter_email', this.email2);
              formData.append('image', this.imageFile); 
              formData.append('nearby', this.nearby);
              formData.append('lng', this.selectedLocation.lng);
              formData.append('lat', this.selectedLocation.lat);

              const id = localStorage.getItem('id')
              await axios.post(`/sellers/${id}`, formData);
              this.loading = false;
              this.snackbar=true;
            }
          }catch (e) {
    console.error('Error logging in:', e);
this.error=e ;
this.loading=false;
  }
        },
        handleImageUpload(event) {
      const file = event.target.files[0]; // Get the first file from the event
      if (file) {
        this.imageUrl = URL.createObjectURL(file); 
        this.imageFile = file;
      }
    },
  },
};
</script>
<style scoped>
.inline-id {
  display: inline-block;
  white-space: nowrap; /* Ensures the text doesn't wrap */
}
.myCard {
  background: linear-gradient(90deg, white, lightgray);

  width: 100%;
}
.top-text {
    font-weight: bold;
    padding: 10px;
    margin-left: 10%;
    margin-right: 10%;
    height: fit-content;
    width: 80%;
    background-color: #D9D9D9;
}

span {
  font-weight: bold;
}
.myCard {
  background: linear-gradient(90deg, white, lightgray);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.card-content {
  display: flex;
  align-items: center;
}

.image-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-button {
  margin-bottom: 20px;
}

.store-image {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.info-column {
  display: flex;
  flex-direction: column;
}

.info-row {
  margin-bottom: 10px;
}

</style>