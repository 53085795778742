<template>
  <main-Layout class="Layout ml-25" height="800">
        <!-- Section Title -->
        <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3">
        Order # {{ Items.id }}
      </p>
    </div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card 
            class="cards mb-3 d-flex flex-row align-center justify-space-between" 
            v-for="(item, i) in Items.items" 
            :key="i"
            color="grey lighten-4" 
            height="70" 
            style="width: 100%;"
          >
            <img :src="item.product.images" style="height: 100%;" />
            <span>{{ item.product.name }}</span>
            <div v-if="item.variation && item.variation.attribute_values && item.variation.attribute_values.length > 0">
              <div v-for="(attribute, index) in item.variation.attribute_values" :key="index">
                {{ attribute.name }}
              </div>
            </div>
            <span>{{ item.product.price }} JOD</span>
            <span>Amount : {{ item.quantity }}</span>
            <div v-if="item.product.size">
              <div v-for="(size, index) in item.product.size" :key="index">
                {{ size }}
              </div>
            </div>
            <span>SKU : {{ item.product.sku }}</span>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <h4>Total discount :</h4>
      <span>{{ Items.total_discount }}</span>
    </v-container> 
    <div class=" d-flex flex-row">
      <div style="width:4%;border-top:1 white;"></div>
      <div style="border-top: 1px solid black;width: 100%;"></div>
      <div style="width:4%;border-top:1 white;"></div>
    </div>
    <v-container>
      <h4>Total :</h4>
      <span>{{ Items.total_price }} JOD</span>
    </v-container> 
    <div class=" d-flex flex-row">
      <div style="width:4%;border-top:1 white;"></div>
      <div style="border-top: 1px solid black;width: 100%;"></div>
      <div style="width:4%;border-top:1 white;"></div>
    </div>
    <v-container>
      <h4>Quantity :</h4>
      <span>{{ Items.total_quantity }}</span>
    </v-container> 
    <div class=" d-flex flex-row">
      <div style="width:4%;border-top:1 white;"></div>
      <div style="border-top: 1px solid black;width: 100%;"></div>
      <div style="width:4%;border-top:1 white;"></div>
    </div>  
    <v-container >
      <h4>date of purchase :</h4>
      <span>{{ Items.created_at }}</span>
    </v-container>
    <div class=" d-flex flex-row">
      <div style="width:4%;border-top:1 white;"></div>
      <div style="border-top: 1px solid black;width: 100%;"></div>
      <div style="width:4%;border-top:1 white;"></div>
    </div>
    <v-container>
      <h4>Status :</h4>
      <span>{{ Items.status }}</span>
    </v-container> 
    <div class=" d-flex flex-row">
      <div style="width:4%;border-top:1 white;"></div>
      <div style="border-top: 1px solid black;width: 100%;"></div>
      <div style="width:4%;border-top:1 white;"></div>
    </div> 
    <v-container>
      <h4>Customer : </h4>
      <h4> {{ customer.first_name }} {{ customer.last_name }}</h4>
    </v-container>
  </main-Layout>
</template>




<script>
import MainLayout from '@/components/global/mainLayout.vue'
import axios from 'axios';
export default{
  components: { MainLayout,  },
data(){
  return{
    Items:[],
    customer:'' ,
  }
},
created(){
this.getDetails();
},
methods:{
async  getDetails(){
  const id = this.$route.params.id;
  const res = await axios.get(`/orders/${id}`)
  this.Items=res.data.data;
  console.log("ITEMS: " , this.Items);
  this.customer=res.data.data.customer;
  }
}
}
</script>
<style>
.top-text {
  font-weight: bold;
  padding: 10px;
  height: 70px;
  background: linear-gradient(to right, #c1c0c0 0%, #fff 100%);
}
</style>