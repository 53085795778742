<template>

<AdminLayout>

  <div class="d-flex justify-space-between mt-5 mb-12" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > 
        {{ $t("Order Lists") }}
      </p>
    </div>
    <v-col>
      <v-text-field
          v-model="searchPoduct"
          :label="$t('Search')"
          outlined
          placeholder="Type to search..."
        >
      </v-text-field>
    </v-col>
    <v-col sm="12"> 
      <v-card :loading="loading">
        <v-card-text>
          <v-table>
            <thead style="background-color: #F5F6FA;color: black;">
              <tr>
                <th> {{ $t("ID") }}</th>
                <th> {{ $t("Seller Name") }}</th>
                <th> {{ $t("total quantity") }}</th>
                <th> {{ $t("total price") }}</th>
                <th> {{ $t("Date-time") }}</th>
                <th> {{ $t("user address") }}</th>
                <th > {{ $t("Status") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filtredOrder" :key="item.name" @click="goToDetails(item.id)" class="table-row-hover">
                <td>{{ item.id }}</td>
                <td>{{ item.seller.name }}</td>
                <td>{{ item.total_quantity }}</td>
                <td>{{ item.total_price }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.user_address.city }}/{{ item.user_address.street }}</td>
                <td>
                  <v-chip class="d-flex justify-center align-center" :style="{ 'background-color': getStatusColor(item.status), width: 'fit-content', 'text-align': 'center' }">{{ $t(item.status) }}</v-chip>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>

</AdminLayout>
</template>


<script>
import AdminLayout from '@/components/global/adminLayout.vue'
import axios from 'axios';

export default {
  components:{AdminLayout},
  computed:{
    filtredOrder : function (){
      return this.orders.filter((order)=> {
        return  (
          order.id.toString().includes(this.searchPoduct) || // Search by ID
          order.seller.name.toLowerCase().includes(this.searchPoduct) // Search by name
  );
      });
    }
  },
  created() {
    this.getOrders();
  },
  data() {
    return {
      loading:false,
      orders:[],
      searchPoduct:'',

    }
  },
  methods:{
    async getOrders() {
      this.loading=true;
      const res = await axios.get('/orders');
      this.orders = [...res.data.data];
      this.loading=false;

    },
      goToDetails(id) {
    this.$router.push({ name: 'orderAdminDetails', params: { id: id } });
  },
  getStatusColor(status) {
    switch (status) {
        case 'pending':
            return '#FFD166'; // Yellow
        case 'delivered':
            return '#06D6A0'; // Green
        case 'failed':
            return '#EF476F'; // Red
        case 'on_the_way':
            return '#118AB2'; // Blue
        case 'returned':
            return '#FF851B'; // Orange
        default:
            return '#ced4da'; // Light Grey
    }
},
  }
}
</script>
<style>
.table-row-hover:hover {
  cursor: pointer;
}
</style>