<template>
    <v-container class="centered-container" >
        <v-form @submit.prevent ref="form" class="responsive-form">
            <!-- ------------------------------- Login ------------------------------- -->
            <v-col>
                <v-card
                    color="primary"
                    variant="tonal"
                    rounded="lg"
                    class="mx-auto mb-12 pa-6"
                    >
                    <v-card-item>
                    <div>
                        <div class="text-h5 mb-1">
                            Join Eboom Today!
                        </div>
                        <div class="text-h7">
                            Sign up with Eboom to enhance 
                            your store's sales and reach 
                            more customers. Enjoy exclusive 
                            features and benefits designed to help y
                            our business thrive.
                            <a href="" style="color: #0D47A1;">How to Register ?</a>
                        </div>
                    </div>
                    </v-card-item>

                    <v-card-actions>
                    
                    <v-btn variant="outlined" class="text-caption" to="/login">
                        Already have an account?
                    </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card class="mx-auto mb-8" title="About Your Store" rounded="lg" variant="tonal"></v-card>    
                <v-col>
                    <v-text-field
                        density="compact"
                        placeholder="First Name"
                        v-model="firstName"
                        :rules="[v => !!v || 'Required']"
                        variant="underlined"
                        >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        density="compact"
                        placeholder="Last Name"
                        v-model="lastName"
                        :rules="[v => !!v || 'Required']"
                        variant="underlined"
                        >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        density="compact"
                        placeholder="Store Name"
                        v-model="storeName"
                        :rules="[v => !!v || 'Required']"
                        variant="underlined"
                        >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-select 
                        density="compact"
                        v-model="selectedCategory" 
                        :items="categories" 
                        placeholder="Select Your Catigories"
                        item-title="name" 
                        item-value="id" 
                        no-data-text="Not Available" 
                        :rules="[v => !!v || 'Catigory Required']"
                        variant="underlined"
                        required
                        >
                    </v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        density="compact"
                        placeholder="Email"
                        v-model="email"
                        :rules="emailRules"
                        required
                        variant="underlined"
                        >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        density="compact"
                        placeholder="Phone Number"
                        v-model="phone"
                        :rules="[v => !!v || 'Required', v => (v && /^[0-9]{9}$/.test(v)) || 'Only 10 Digits']"
                        required
                        variant="underlined"
                        type="number"
                        pattern="\d*"
                        :counter="10"
                        >
                    </v-text-field>
                </v-col>
                <v-card class="mx-auto mb-8" title="Locations your Store Is" rounded="lg" variant="tonal" ></v-card>   
                <v-col>
                    <GoogleMap 
                        :api-key="apikey"
                        style="width: 100%; height: 400px" 
                        :center="center" 
                        :zoom="13" 
                        @click="handleMapClick">
                        <Marker 
                            :options="{ position: selectedLocation, draggable: true }" 
                        />
                    </GoogleMap>
                </v-col>
                <v-col>
                    <v-select 
                        density="compact"
                        v-model="city" 
                        :items="cities" 
                        variant="underlined"
                        :rules="[v => !!v || 'City Required']"
                        placeholder="Select a city"
                        required
                        >
                    </v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        density="compact"
                        placeholder="Nearby"
                        v-model="nearby"
                        variant="underlined"
                        >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        density="compact"
                        placeholder="Street Address"
                        v-model="address"
                        variant="underlined"
                        :rules="[v => !!v || 'Required']"
                        >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-textarea 
                        density="compact"
                        v-model="notes" 
                        placeholder="We would greatly appreciate your insights and recommendations"
                        >
                    </v-textarea>
                </v-col>
                <v-col>
                    <v-checkbox 
                        density="compact"
                        v-model="terms" 
                        label="I Agree To The Private & Secure Terms."
                        :rules="[v => !!v || 'You Must Agree To The Terms.']"
                        >
                    </v-checkbox>
                </v-col>
            </v-col>
            <v-col>
                <v-btn
                    density="compact"
                    type="submit"
                    class="mb-2 pa-8"
                    color="blue"
                    size="large"
                    variant="tonal"
                    block
                    :loading="loading"
                    :disabled="!isFormValid"
                    @click.prevent="submit"
                    >
                    Sign Up
                </v-btn>
            </v-col>
                <error 
                    v-if="error"  
                    :error="error">
                </error> 


            <!-- ------------------------------- Welcome Dealog ------------------------------- -->
            <v-dialog
                v-model="WelcomeDialog"
                width="auto"
                >
                <v-card
                    class="border-card pa-5"
                    max-width="700"
                    rounded="xl"
                >
                    <v-card-title class="text-h5">
                    <v-icon left class="mr-2">mdi-checkbox-marked-circle</v-icon>
                    Great
                    </v-card-title>

                    <v-card-text>
                    You submitted correctly and it will be in progress. We will notify you if all your information is correct.
                    </v-card-text>

                    <v-card-actions>
                    <v-btn
                        class="ma-2"
                        color="primary"
                        to="/login"
                    >
                        Go to Login
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </v-container>
</template>


<script>
import axios from 'axios';
import { ref } from 'vue';
import { GoogleMap, Marker } from 'vue3-google-map';
import error from '@/components/global/errorView.vue';
import cities from '@/locale/cities.json';


export default {
  components: { 
    GoogleMap,
    Marker,
    error
    },
  computed: {
    isFormValid() {
      return (
        !!this.firstName &&
        !!this.lastName &&
        !!this.storeName &&
        !!this.catigory &&
        !!this.city &&
        !!this.nearby &&
        !!this.address &&
        !!this.phone &&
        !!this.email &&
        !!this.notes &&
        !!this.terms=== true 
      );
    }
  },
    data() {
        return {
          apikey:process.env.VUE_APP_API_KEY_GOOGLe_MAPs,
            WelcomeDialog: false,
            error: '',
            loading: false,
            terms: false,
            center: { lat: 31.963158, lng: 35.930359 },
            selectedLocation: ref({ lat: 0, lng: 0 }),
            firstName: '',
            lastName: '',
            storeName: '',
            websiteStore: 'ExampleWebsiteFuture',
            categories: [],
            cities: cities,
            selectedCategory: null,
            city: null,
            nearby: '',
            address: '',
            phone: '',
            phone2: null,
            email: '',
            email2: '',
            notes: '',
            storeNameRules: [(v) => !!v || "store name is required"],
            emailRules: [(v) => !!v || "Email is required", (v) => /.+@.+\..+/.test(v) || "Enter a valid email address"],
        }
    },
    methods: {
      handleMapClick(event) {
      this.selectedLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },


    async showCategories() {
  let page = 2;
  let hasNextPage = true;
  while (hasNextPage) {
    try {
      const res = await axios.get(`/categories?page=${page}`);
      const categories = res.data.data;
      
      if (!categories || categories.length === 0) {
        hasNextPage = false;
      } else {
        this.categories.push(...categories);
        page++;
      }
    } catch (error) {
      if (error.response.status === 401) {
        // Handle unauthenticated error (e.g., redirect to login)
        console.error('User is not authenticated. Redirecting to login.');
        // Example: Redirect to login page
        // this.$router.push('/login');
      } else {
        console.error('Error fetching categories:', error.message);
        // Handle other errors as needed
      }
      hasNextPage = false; // Exit loop on error
    }
  }
},


    async submit() {
        this.loading = true;
        try {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                const formData = new FormData();
                const phone = '00962' + this.phone;
                formData.append('first_name', this.firstName);
                formData.append('last_name', this.lastName);
                formData.append('phone', phone);
                formData.append('store_name', this.storeName);
                formData.append('email', this.email);
                formData.append('category_id', this.catigory);
                formData.append('alter_phone', this.phone2);
                formData.append('notes', this.notes);
                formData.append('nearby', this.nearby);
                formData.append('website', this.websiteStore);
                formData.append('street', this.address);
                formData.append('lng', this.selectedLocation.lng);
                formData.append('lat', this.selectedLocation.lat);
                formData.append('city', this.city);

                await axios.post('/auth/seller-register', formData)
                    .then(response => {
                      console.log(response)
                        // When the sign up is done, open the Welcome Dialog
                        this.WelcomeDialog = true;
                        this.loading = false;
                    })
                    .catch(error => {
                        console.error('Error registering seller:', error);
                        this.error = error.response.data.message;
                        this.errors = error.response.data.errors || {};
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        } catch (e) {
            console.error('Unexpected error:', e);
            this.loading = false;
        }
    }
},

mounted() {
    this.showCategories(); // Example: Automatically fetch categories when component is mounted
  },
}
</script>
<style scoped>
.responsive-form {
  width: 75%;
  margin: 0 auto; /* Center the form */
}

/* Media query for small screens */
@media (max-width: 600px) {
  .responsive-form {
    width: 100%;
  }
}
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}
</style>