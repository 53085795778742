<template>
  <AdminLayout>
    <snackbar   :snackbar="snackbar" :snackbarColor="snackbarColor" :snackbarMessage="snackbarMessage" @close="snackbar = false"></snackbar>
    <div class="d-flex justify-space-between mt-5 mb-12" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > 
        {{ $t("Admissions") }}
      </p>
    </div>
        <v-col sm="12"> 
          <v-card :loading="loading">
            <v-card-text>
              <v-table>
                <thead style="background-color: #F5F6FA;">
                  <tr>
                    <th>  {{ $t("Store name") }}</th>
                    <th> {{ $t("Catigory") }}</th>
                    <th> {{ $t("Location") }}</th>
                    <th> {{ $t("Email") }}</th>
                    <th> {{ $t("Phone") }}</th>
                    <th> {{ $t("Date") }}</th>
                    <th> {{ $t("Actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data" :key="item.id">
                    <td>{{ item.store_name }}</td>
                    <td>{{ item.category.name }}</td>
                    <td>{{ item.address.city }}, {{ item.address.country }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.created_at }}</td>
                  <td>
                    <v-btn  @click="confermation('0',item.id)"><v-icon color="red" >mdi-close-circle-outline</v-icon></v-btn>
                    <v-btn  @click="confermation('1',item.id)"><v-icon color="green" >mdi-check-outline</v-icon> </v-btn>
                  </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
    

  </AdminLayout>
  </template>
  <script>
  import AdminLayout from '@/components/global/adminLayout.vue'
  import axios from 'axios';
  import snackbar from '@/components/global/snackBar.vue'

export default {
  components: { AdminLayout,snackbar },
  data() {
    return {
      loading: false,
      deleteId:null,
      snackbar: false,
      snackbarColor: 'success',
      snackbarMessage: '',
      data: [],
    };
  },
  created(){
    this.getData();
  },
  methods: {
    async getData(){ 
      this.loading=true;
      try {
        let page = 1;
    let hasNextPage = true;
    while (hasNextPage) {
    const res = await axios.get(`/sellers?page=${page}`);
    const subCategories = res.data.data;

    // If the sub-categories array is empty or null, there are no more pages
    if (!subCategories || subCategories.length === 0) {
      hasNextPage = false;
    } else {
      this.data.push(...subCategories); // Add the fetched sub-categories to the existing array
      page++; 
    }
  }
    } catch (error) {
        console.error('Error fetching products:', error);
    }finally{
      this.loading=false;
    }
      
    },
  async  confermation(chick , id){
  this.loading = true
          try{
            {
              // const name= this.seller.name;
              const formData = new FormData();
              formData.append('_method', 'PUT');
              formData.append('status', chick);
              await axios.post(`/sellers/${id}`, formData);
              this.loading = false;
              if (chick ==='1') {          
                this.snackbarMessage = 'store  accepted';
                this.snackbarColor = 'success';
                this.snackbar = true;
              }
              if (chick ==='0') {          
                this.snackbarMessage = 'store not accepted';
                this.snackbarColor = 'warning';
                this.snackbar = true;
              }
            }
          }catch (e) {
            alert ('Error logging in:', e )
          this.loading=false;
          }
},
  }
}
  </script>