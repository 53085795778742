<template>
  <main-Layout class="Layout " style="width: 100%;">
    <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3">
        {{ $t("Product Info") }}
      </p>
    </div>
    <v-row v-row class="mx-2 mt-2" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'" style="height: fit-content;">
      <v-col cols="12" md="4" class="d-flex justify-center">
        <v-card
          round
          :loading="loading"
          class="card"
          >
          <v-img
            height="200px"
            v-if="data.images && data.images.length > 0"
            :src="data.images[0]"
            cover
            :loading="loading"
            >
          </v-img>
          <v-card-title>
            {{data.name }}
          </v-card-title>
          <v-card-subtitle>
            Price: {{ data.price }}JOD
            <br />
            <span v-if="data.discount">Discount: {{ data.discount }}%</span>
            <br v-if="data.discount" />
          </v-card-subtitle>
          <v-card-actions class="d-flex justify-space-between">
            <v-btn variant="tonal" rounded :to="{ name: 'edit', params: { id: data?.id }  }" :loading="loading">
              <v-icon icon="mdi-wrench"></v-icon>
              &nbsp;&nbsp; edit product info
            </v-btn>
            <v-btn variant="tonal" rounded @click="deleteProduct()" :loading="loading">delete</v-btn>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-text>
            Description : <br />
            {{ data.description }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="d-flex flex-column">
        <div v-for="(card, index) in dataCards" :key="index">
          <div v-for="(value, key) in card" :key="key" >
            <v-card v-if="value" class="card" height="100">
              <div class="d-flex flex-column align-center justify-space-between">
                <v-card-subtitle class="mt-4">{{ formatKey(key) }}</v-card-subtitle>
                <v-card-title class="text-h4 mt-1">{{ value }}</v-card-title>
              </div>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </main-Layout>
</template>



<script>
import axios from 'axios';
import MainLayout from '@/components/global/mainLayout.vue'
//import Visitorschart from '@/components/charts/VisitorsChart.vue'
//import TotalSales from '@/components/charts/TotalSales.vue'



export default{
  components: { MainLayout,/*Visitorschart,TotalSales*/  },
  data(){
    return{
data:[],
dataCards:[],
loading:false
    }
  },
  created(){
this.callDetails();
  }
  ,
  methods:{
    formatKey(key) {
      // Replace underscores with spaces and capitalize the first letter of each word
      return key.replace(/_/g, ' ').replace(/\b\w/g, firstChar => firstChar.toUpperCase());
    },
  async callDetails(){
  this.loading=true;
  const id = this.$route.params.id;
  
const res = await axios.get(`/products/${id}`)
this.data=res.data.data.product;
this.loading=false
  // Extract specific information from data and populate dataCards
  this.dataCards = [
    {
      total_rating: this.data.total_rating, 
      total_selling: this.data.total_selling, 
      total_amount_selling: this.data.total_amount_selling, 
      views: this.data.views, 
      quantity: this.data.quantity, 
      shipping_weight: this.data.shipping_weight, 
      sku: this.data.sku, 
      
    }
  ];
},
async deleteProduct(){
  const id = this.$route.params.id;

  await axios.delete(`/products/${id}`)
  this.$router.push('/inventory')
}
  }

}
</script>

<style>
.top-text {
  font-weight: bold;
  padding: 10px;
  height: 70px;
  background: linear-gradient(to right, #c1c0c0 0%, #fff 100%);
}
.card {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
.card-item {
  flex: 1 0 calc(33.333% - 10px); /* Adjust the width based on the number of items per row */
}
.key {
  font-weight: bold;
}
.value {
  margin-top: 10px;
}
</style>