<template>
  <v-container class="mx-auto pa-md-12 pa-sm-6" fluid>
    <v-responsive
      class="text-center mx-auto mb-6 mb-md-12"
      max-width="700"
    >
      <router-link to="/">
        <img src="@/assets/images/EboomTitle.png" class="responsive-img" />
      </router-link>
      <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title">
        {{ $t('aboutUs.title') }}
      </h1>

      <p class="text-subtitle-1 text-medium-emphasis">
        {{ $t('aboutUs.intro') }}
      </p>

    </v-responsive>
 
    <v-lazy
      :min-height="200"
      :options="{'threshold':0.5}"
      transition="fade-transition"
    >
    <div :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <v-col class="pa-md-12 pa-sm-6">
        <v-row class="align-center justify-center">
          <v-col>
            <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title">
              {{ $t('aboutUs.whatIsEboom.title') }}
            </h1>

            <p class="text-subtitle-1 text-medium-emphasis">
              {{ $t('aboutUs.whatIsEboom.description') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col>
            <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title">
              {{ $t('aboutUs.journeyOfInnovation.title') }}
            </h1>

            <p class="text-subtitle-1 text-medium-emphasis">
              {{ $t('aboutUs.journeyOfInnovation.description') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col>
            <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title">
              {{ $t('aboutUs.creativityAndChange.title') }}
            </h1>

            <p class="text-subtitle-1 text-medium-emphasis">
              {{ $t('aboutUs.creativityAndChange.description') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col>
            <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title">
              {{ $t('aboutUs.futureOfCommerce.title') }}
            </h1>

            <p class="text-subtitle-1 text-medium-emphasis">
              {{ $t('aboutUs.futureOfCommerce.description') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col>
            <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title">
              {{ $t('aboutUs.rootedInJordan.title') }}
            </h1>

            <p class="text-subtitle-1 text-medium-emphasis">
              {{ $t('aboutUs.rootedInJordan.description') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col>
            <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title">
              {{ $t('aboutUs.registerStore.title') }}
            </h1>

            <p class="text-subtitle-1 text-medium-emphasis">
              <a href="/register">{{$t('Click Here')}}</a>, {{ $t('aboutUs.registerStore.instruction') }}
            </p>
          </v-col>
        </v-row>
    </v-col>
   </div>
   </v-lazy>
  </v-container>
</template>

<style>

.responsive-img{
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}
</style>
