<template >
  <main-Layout style="width: 100%; height: 100%;" class="backgroundcolor">


    <!-- Section Title -->
    <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3">
        {{ $t("Dashboard") }}
      </p>
    </div>

    <v-row class="mx-2 mt-2" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'" style="height: fit-content;">
      
      <!-- Profile Information -->
      <v-col cols="12" md="4" class="d-flex justify-center">
        <v-card class="card" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
          <div class="d-flex justify-end align-content-start">
            <span>{{ $t("ID") }}: {{ id }}</span>
          </div>
          <v-col class="text-center">
            <img v-if="image" :src="image" class="profile-image">
            <img v-else src="@/assets/images/account.png" class="profile-image">
          </v-col>
          <v-col class="text-info">
            <div class="info-item">
              <span>{{ $t("Shop name") }} : </span><span>{{ store_name }}</span>
            </div>
            <div class="info-item">
              <span>{{ $t("Email") }} : </span><span>{{ email }}</span>
            </div>
            <div class="info-item">
              <span>{{ $t("Phone number") }} : </span><span>{{ phone }}</span>
            </div>
          </v-col>
          <RouterLink to="/profile">
            <v-icon class="pencil-icon">mdi-pencil</v-icon>
          </RouterLink>
        </v-card>
      </v-col>

      <!-- Cards Section -->
      <v-col cols="12" md="8" class="d-flex flex-column">
        <v-row v-for="(card, index) in analysis" :key="index">
          <v-col cols="12">
            <v-card class="card" height="150" color="info">
              <div class="d-flex align-center justify-space-between">
                <div>
                  <v-card-subtitle class="mt-4">{{ card.name }}</v-card-subtitle>
                  <v-card-title class="text-h4 mt-1">{{ card.number }}</v-card-title>
                </div>
                <v-avatar size="50" class="bg-amber-accent-1">
                  <img alt="Icon" :src="icons[index % icons.length].url">
                </v-avatar>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
    <!-- Sales Grahp 24h -->
    <v-col>
      <v-card
        class="mx-auto text-center"
        color="green"
        max-width="100%"
        dark
      >
        <v-card-text>
          <v-sheet color="rgba(0, 0, 0, .12)">
            <v-sparkline
              :model-value="value"
              color="rgba(255, 255, 255, .7)"
              height="100"
              padding="24"
              stroke-linecap="round"
              smooth
            >
            <template v-slot:label="item">
              ${{ item.value }}
              </template>
            </v-sparkline>
          </v-sheet>
        </v-card-text>

        <v-card-text>
          <div class="text-h4 font-weight-thin">
            Sales Last 24h
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center">
          <v-btn
            variant="text"
            block
          >
            Go to Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

  </main-Layout>
</template>

<script>
import MainLayout from '@/components/global/mainLayout.vue'

export default {
  components: { MainLayout },
  data() {
    return {
      analysis: [
        { name: 'Total Orders', number: 0 },
        { name: 'Total Sales', number: 0 },
        { name: 'Total Products', number: 0 },
      ],
      icons: [
        { url: require('@/assets/icons/Iconchart.png'), case: 'person' },
        { url: require('@/assets/icons/Iconuser.png'), case: 'chart' },
        { url: require('@/assets/icons/Iconsoon.png'), case: 'box' },
      ],
      id: localStorage.getItem('id'),
      image: localStorage.getItem('image'),
      first_name: localStorage.getItem('first_name'),
      email: localStorage.getItem('email'),
      phone: localStorage.getItem('phone'),
      store_name: localStorage.getItem('store_name'),
      value: [
        423,
        446,
        675,
        510,
        590,
        610,
        760,
      ],
    }
  }
};
</script>

<style scoped>
.backgroundcolor{
  background-color: #F5F6FA;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.profile-image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin: 10px auto;
  display: block;
}

.text-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.info-item {
  margin-bottom: 10px;
  color: black;
}

.pencil-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}


span {
  font-weight: bold;
}
</style>
