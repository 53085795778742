<template>
  <v-container class="mx-auto pa-md-12 pa-sm-6 text-center justify-center align-center" fluid>
    <v-responsive
      class="text-center mx-auto mb-6 mb-md-12"
      max-width="700"
      >
      <router-link to="/">
        <img src="@/assets/images/EboomTitle.png" class="responsive-img" />
      </router-link>
      <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title" style="font-weight: 200;">
        {{$t("Contact US")}}
      </h1>
    </v-responsive>
      <div class="justify-center align-center">
        <v-btn
          v-for="icon in icons"
          :key="icon.name"
          :icon="icon.name"
          class="mx-4"
          variant="text"
          :href="icon.url"
          target="_blank"
          >
        </v-btn>
      </div>
      <v-row class="d-flex justify-center align-center my-12">
        <v-card class="mx-auto mb-6 mb-md-12" width="80%" height="fit-content" elevation="0" >
          <h3 class="text-cente ma-8">{{$t('Send US an Email')}}</h3>
          <v-form ref="form"  >
            <v-col>
              <v-col cols="12">
                <response v-if="res" :res="res"></response>
                <error v-if="error" :error="error" ></error>
              </v-col>
              <v-col cols="12" class=" d-flex flex-column justify-center align-center"> 
                <v-text-field variant="underlined" class="mt-3" v-model="username" label="Your Name"  :rules="usernameRules" style="width: 100%;" ></v-text-field>
                <v-text-field variant="underlined" class="mt-3" v-model="email" :rules="emailRules" label="E-mail"  style="width: 100%;" ></v-text-field>
                <v-textarea v-model="message" label="Type your message" :rules="[v => !!v || 'message is required']"   style="width: 100%;" ></v-textarea>
                <v-btn class="my-3" @click="sendMessage"   color="black">{{$t('Send Message')}}</v-btn>
              </v-col>
            </v-col>
          </v-form>
        </v-card>
      </v-row>
</v-container>
</template>
<script>
import axios from 'axios';
import response from '@/components/global/response.vue'
import error from '@/components/global/errorView.vue'
export default {
  
  data() {
    return {
      user:localStorage.getItem('id'),
      res:null,
      error:null,
      username: '',
      message: '',
      email: '',
      emailRules: [
        (v) => !!v || "Email is required", // Ensures that email is not empty or null
        (v) => /.+@.+\..+/.test(v) || "Enter a valid email address", // Validates email format
        (v) => /^[^0-9]/.test(v) || "Email should not start with a number" // Ensures email doesn't start with a number

      ],
      usernameRules: [
            (v) => !!v || "Username is required",
            (v) => v.length <= 100 || "Username must be less than 100 characters"
      ],
      icons: [
        { name: 'mdi-instagram', url: 'https://www.instagram.com/eboom_jo/' },
      ],
    }
  },
  components:{response,error},
  methods: {
    async sendMessage() {
    try {
      const form = this.$refs.form;
    // Check if the form is valid
      if (form && !form.validate()) {
        // Form is not valid, return and do not proceed with submission
        return;
      }
        // Proceed with sending the message
        const payload = {
            email: this.email,
            description: this.message,
            username: this.username
        };

        const res = await axios.post('/contact-us', payload);
        this.res = res.data.data.success;

        
        // Reset form fields after successful submission
        this.email = '';
        this.message = '';
        this.username = '';
    } catch (error) {
        this.error = 'error'+error;
    }
},

  }
}

</script>
<style>
</style>