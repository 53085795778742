<template>
  <AdminLayout>
    <v-card>
      <div class="d-flex justify-space-between mt-5" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-5" > {{ $t("ADD admin") }}</p>
    </div>
    <v-container>
      <v-form ref="form"> 

        <v-row justify="center">
          <v-col cols="12" md="6" class="text-center">
    <label for="fileInput" class="avatar-label">
      <v-avatar size="200">
        <img :src="imageUrl || require('@/assets/icons/Image.png')" alt="Avatar" >
      </v-avatar>
    </label>
    <input type="file" id="fileInput" style="display: none;" @change="handleImageUpload">
  </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field v-model="first_name" :label="$t('First name')"  :rules="[v => !!v || 'first_name is required']"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="last_name" :label="$t('Last name')"  :rules="[v => !!v || 'last_name is required']"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="email" :label="$t('Email')"  :rules="emailRules" ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="password" :label="$t('Password')"  :rules="passwordRules" ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="phone" type="tel" :label="$t('Phone')" placeholder=" example : 7 71 111 111"  :rules="[v => !!v || 'Phone number is required', v => (v && /^[0-9]{9}$/.test(v)) || 'Phone number must be 10 digits']" ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <v-btn class="mr-4" :loading="loading" :text="$t('ADD')"  width="150px" rounded color="black" @click="addAdmin()"></v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
  <v-col sm="12"> 
          <v-card>
            <v-card-text>
              <v-table>
                <thead style="background-color: #F5F6FA;">
                  <tr>
                    <th> {{ $t("ID") }}</th>
                    <th> {{ $t("First name") }}</th>
                    <th> {{ $t("Email") }}</th>
                    <th> {{ $t("Phone") }}</th>
                    <th> {{ $t("Date created") }}</th>
                    <th> {{ $t("Actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.created_at }}</td>
                  <td>
                    <v-btn @click="deleteItem(item.id)"><v-icon color="red" >mdi-delete</v-icon></v-btn>
                  </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-dialog :loading="deleteloading" v-model="deleteConfirmation" max-width="500">
      <v-card>
        <v-card-title class="headline"> {{ $t("Are you sure you want to delete this item?") }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="deleteloading" color="red darken-1" text @click="deleteConfirmed"> {{ $t("Yes") }}</v-btn>
          <v-btn color="primary darken-1" text @click="deleteCancelled"> {{ $t("No") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </AdminLayout>
  </template>
  <script>
  import AdminLayout from '@/components/global/adminLayout.vue'
import axios from 'axios';
  export default {
    components:{AdminLayout},
    data() {
      return {
        passwordRules: [
    value => !!value || 'Password is required',
    value => (value && value.length >= 8) || 'Password must be at least 8 characters',
    //value => (/[a-z]/.test(value)) || 'Password must contain at least 1 lowercase letter',
    //value => (/[A-Z]/.test(value)) || 'Password must contain at least 1 capital letter',
    //value => (/[!@#$%^&*(),.?":{}|<>]/.test(value)) || 'Password must contain at least 1 special character',
],
      emailRules: [
        (v) => !!v || "Email is required",  
        (v) => /.+@.+\..+/.test(v) || "Enter a valid email address"  // Validates email format
      ],
        deleteConfirmation: false,
        deleteloading: false,
        loading: false,
        first_name:'',
        last_name:'',
        password:'',
        email:'',
        phone:'',
        imageUrl:'',
        imageFile:null,
        deleteID:'',
        data: [],
      }
    },
    created() {
      this.getData ();
    },
    methods: {
      async getData ()
      {
        const res = await axios.get("/admins?page=1");
        this.data=res.data.data
      },
      handleImageUpload(event) {
      const file = event.target.files[0]; // Get the first file from the event
      if (file) {
        this.imageUrl = URL.createObjectURL(file); 
        this.imageFile = file;      }
    },
    async addAdmin(){
    try{
      this.loading= true;
      if (this.$refs.form && await this.$refs.form.validate())
{
  const phone ='00962'+this.phone;
  const formData = new FormData();
formData.append('first_name', this.first_name);
formData.append('last_name', this.last_name);
formData.append('email', this.email);
formData.append('password', this.password);
formData.append('phone', phone);
formData.append('image', this.imageFile); 

await axios.post('/admins', formData);
}
    this.loading= false;
    alert("done")
    }catch (e) {
    console.error('Error:', e);
this.error=e;
this.loading= false;
  }finally{
    this.getData ();

  } 
  },
  async  deleteConfirmed(){
    this.deleteloading= true;
    const id = this.deleteID;
  await axios.delete(`/admins/${id}`);
  this.deleteloading= false;
  this.deleteConfirmation=false;
  this.getData ();

    },
    deleteCancelled() {
      this.deleteConfirmation = false;
    }, 
    deleteItem(id) {
      this.deleteConfirmation = true;
      this.deleteID=id;

console.log(id) 
    },
    },
  }
  </script>