<template>
    <v-dialog v-model="internalDialog" max-width="300">
      <v-card rounded="lg">
        <v-card-title>{{ $t('Select Language') }}</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="locale in $i18n.availableLocales"
              :key="locale"
              @click="changeLocale(locale)"
            >
              <v-list-item-title>{{ locale }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="tonal" text @click="internalDialog = false">{{ $t('Close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      internalDialog: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        },
      },
    },
    methods: {
      changeLocale(locale) {
        this.$i18n.locale = locale;
        this.internalDialog = false;
      },
    },
  };
  </script>
  