<template>
  <MainLayout class="Layout ml-16" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
    <snackbar   :snackbar="snackbar" :snackbarColor="snackbarColor" :snackbarMessage="snackbarMessage" @close="snackbar = false"></snackbar>
    <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3">
        Edit Product
      </p>
    </div>
    <v-form ref="form" style="height: fit-content;">
      <v-container  class="pa-md-8 pa-sm-4" fluid>
        <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
          <p class="text-h5 font-weight-bold mt-3" >
            General
          </p>
        </div>
        <v-card variant="tonal" color="primary" class="pa-4" rounded="lg" >
          <div style="color: black;">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-switch style="width: 500px;" v-model="is_visiable" :label="$t('Visible To Customers')" :color="is_visiable ? 'indigo' : 'red'" hide-details inset></v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-switch style="width: 500px;" v-model="out_of_stock" :label="$t('Out of Stock')" :color="out_of_stock ? 'indigo' : 'red'" hide-details inset></v-switch>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-container>
      <!-- New Form For Basic information -->
      <v-container  class="pa-md-8 pa-sm-4" fluid>
        <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
          <p class="text-h5 font-weight-bold mt-3" >
            Product Basic Information
          </p>
        </div>
        <v-row>
          <v-col cols="12" md="6">
            <v-card variant="tonal" color="primary" class="pa-4" rounded="lg" >
              <div style="color: black;">
                <h4 class="mb-10"> {{ $t("Product Basic Info in English") }}</h4>
                <v-text-field  class="my-4" variant="outlined" v-model="name_en" :rules="[v => !!v || 'Title is required']" label="Product Title"></v-text-field>
                <v-textarea variant="outlined" label="Description" v-model="description_en" :rules="[v => !!v || 'Description is required']"></v-textarea>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card variant="tonal" color="primary" class="pa-4" rounded="lg">
              <div style="color: black;">
                <h4 class="mb-10"> {{ $t("Product Basic Info in Arabic") }}</h4>
                <v-text-field variant="outlined" v-model="name_ar" :rules="[v => !!v || 'الاسم مطلوب']" dir="rtl" class="text-right my-4" label="اسم المنتج"></v-text-field>
                <v-textarea variant="outlined" label="وصف المنتج" v-model="description_ar" dir="rtl" :rules="[v => !!v || 'الوصف مطلوب']" class="text-right"></v-textarea>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container  class="pa-md-8 pa-sm-4" fluid>
        <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
          <p class="text-h5 font-weight-bold mt-3" >
            Product Images
          </p>
        </div>
        <v-card variant="tonal" color="primary" class="pa-4" rounded="lg">
          <v-row class="d-flex flex-wrap align-start">
            <!-- Add Photo Card -->
            <v-col cols="auto" class="d-flex align-start justify-start">
              <v-card
                  style="height: 100px; width: 100px;"
                  class="d-flex align-center justify-center"
                  @click="triggerFileInput"
                >
                <img src="@/assets/images/add_photo.png" style="height: 100%; width: 100%; object-fit: contain;" />
              </v-card>
            </v-col>
            <!-- Image Cards -->
            <v-col
                v-for="(image, index) in images"
                :key="index"
                cols="auto"
                class="d-flex align-start justify-start"
              >
              <v-card style="height: 100px; width: 100px; position: relative;">
                <img :src="image.url" style="height: 100%; width: 100%; object-fit: contain;" />
                <v-btn
                  icon
                  width="30px"
                  height="30px"
                  @click.stop="removeImage(index)"
                  style="position: absolute; top: 3px; right: 3px; background-color: #EF3826;"
                  >
                  <v-icon size="small">mdi-close</v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
          <input
            type="file"
            id="fileInput"
            style="display: none;"
            accept="image/png, image/jpeg, image/gif, image/svg+xml, image/bmp, image/webp"
            multiple
            @change="handleFileChange"/>
        </v-card>
        <span class="text-body-2"> {{ $t("to add multi photos hold") }} <span class="text-body-2 font-weight-bold">CTRL</span>  {{ $t("and select your photos") }}</span>
      </v-container>
      <v-container  class="pa-md-8 pa-sm-4" fluid>
        <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
          <p class="text-h5 font-weight-bold mt-3" >
            Product Pricing and Category
          </p>
        </div>
        <v-row>
          <v-col>
            <v-card variant="tonal" color="primary" class="pa-4" rounded="lg" >
              <div style="color: black;">
                <h4 class="mb-10"> {{ $t("Product Price") }}</h4>
                <v-col>
                  <v-text-field variant="outlined" v-model="price" :rules="[v => !!v || 'Price is required']" :label="$t('Price')"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field variant="outlined" v-model="discount" :label="$t('Discount %')"></v-text-field>
                </v-col>
                <h4 class="mb-10"> {{ $t("Product Category") }}</h4>
                <v-col>
                  <v-select v-model="catigory" :rules="[v => !!v || 'Catigory is required']" :items="subCategories" item-title="name" item-value="id" no-data-text="No data available" label="Sub Catigory"></v-select>
                </v-col>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container  class="pa-md-8 pa-sm-4" fluid>
        <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
          <p class="text-h5 font-weight-bold mt-3" >
            Product Details
          </p>
        </div>
        <!-- color and size table  -->
        <v-card>
          <v-table style=" width: 100%;">
            <thead style="background-color: #E3F2FD;color: black;">
              <tr>
                <th class=" font-weight-bold"> {{ $t("Color") }}</th>
                <th class=" font-weight-bold"> {{ $t("Size") }} </th>
                <th class=" font-weight-bold"> {{ $t("Quantity") }}</th>
                <th class=" font-weight-bold"> {{ $t("Price") }}</th>
                <th class=" font-weight-bold"> {{ $t("Actions") }}</th>
              </tr>
            </thead>
            <tbody class="justify-space-between">
              <tr v-for="(item, index) in sizeColor" :key="index" class="pa-4">
                <td>
                  <div :style="{ backgroundColor: item.code, height: '33px', width: '33px', borderRadius: '50%',border: '1px solid black' }"></div>
                </td>
                <td>{{ item.size }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.price }}</td>
                <td>
                  <v-btn width="38px" height="38px" icon="mdi-delete" color="error" @click="deleteItem(index)"></v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
          <v-card-actions>
            <v-btn width="fit-content" color="black" rounded @click="openDialog"> {{ $t("Add product details") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
      <!-- add dialog -->
      <v-dialog v-model="dialog" max-width="500">
        <v-card rounded="lg" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
          <v-card-title> {{ $t("Add Item") }}</v-card-title>
          <v-col>
            <center>
              <v-color-picker v-model="newItem.code"></v-color-picker>
            </center>            
          </v-col>
          <v-col>
            <v-text-field v-model.number="newItem.quantity" :label="$t('Quantity')" :rules="[v => !!v || 'Quantity is required']"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model.number="newItem.price" :label="$t('Price')"></v-text-field>
          </v-col>
          <v-col>
            <select class="myselect" v-model="newItem.size">
              <option value="">None</option>
              <option v-for="size in sizes" :key="size.id" :value="size.id">{{ size.name }}</option>
            </select>
          </v-col>
          <v-col>
            <select class="myselect" v-model="newItem.color">
              <option value="">None</option>
              <option v-for="color in colors" :key="color.id" :value="color.id">{{ color.name }}</option>
            </select>
          </v-col>       
          <v-card-actions>
            <v-btn color="black" text @click="dialog = false"> {{ $t("Cancel") }}</v-btn>
            <v-btn color="black" @click="addItem"> {{ $t("Add") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Inventory and Shipping Section -->
      <v-container  class="pa-md-8 pa-sm-4" fluid>
        <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
          <p class="text-h5 font-weight-bold mt-3" >
            {{ $t("Inventory and Shipping") }}
          </p>
        </div>
        <v-card variant="tonal" color="primary" class="pa-4" rounded="lg" >
          <div style="color: black;">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="tags" :rules="[v => !!v || 'Tags is required']" label="Tags"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="sku" label="SKU"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="quantity" :rules="[v => !!v || 'Quantity is required']" type="number" :label="$t('Quantity')"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="shipping_weight" :rules="[v => !!v || 'shipping weight is required']" prepend-inner-icon="mdi-weight-kilogram" type="number" :label="$t('Product weight')"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-container>
      <v-container class="pa-md-8 pa-sm-4" fluid>
        <v-row class="d-flex justify-end mt-5 mb-8">
          <v-col class="d-flex" cols="auto">
            <v-btn class="mr-2" color="red" rounded @click="cancel"> {{ $t("Cancel") }}</v-btn>
            <v-btn class="mr-2" :loading="loading" :disabled="!isFormValid" type="submit" @click.prevent="submit" rounded color="black" width="200px"> {{ $t("Save") }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </MainLayout>
</template>


<script>
import MainLayout from '@/components/global/mainLayout.vue'
import axios from 'axios';
import snackbar from '@/components/global/snackBar.vue'
export default {
  components: { MainLayout,snackbar },
  data() {
      return {
          loading:false,
          name_en: '', 
          description_en: '',
          name_ar: '', 
          description_ar: '',
          price: null,
          discount:null,
          discount_type:'percentage',
          tags: [],
          sku:'',
          quantity: null,
          catigory: '',
          shipping_weight: null,
          images: [],
          is_visiable: null, 
          out_of_stock: null ,
          subCategories: [],
          data:[],
          sizeColor:[],
          variations:[],
          dialog: false,
          newItem:{color: '',size: null,quantity: null,price: null},
          imgUrl: [],
          img: '',
          snackbar: false,
          snackbarColor: 'success',
          snackbarMessage: '',
      }
  },
  created(){
    this.getData();
    this.getSubCatigory();
  },
  computed: {
    isFormValid() {
      return (
        !!this.name_en &&
        !!this.name_ar &&
        !!this.description_en &&
        !!this.description_ar &&
        !!this.price &&
        !!this.tags &&
        //!!this.sku &&
        !!this.quantity &&
        !!this.catigory &&
        !!this.shipping_weight 
        //&&!!this.images.length
      );
    }
  },
  methods: {
    async submit() {
      this.loading = true; // Set loading to true immediately
      try {
        const form = this.$refs.form;
        const is_valid = await form.validate();
        if (!is_valid) {
          this.loading = false; // Set loading to false if the form is not valid
          return; // Return early if validation fails
        }

        const formData1 = new FormData();
        // Append form data
        formData1.append('_method', 'PUT');
        formData1.append('name_en', this.name_en);
        formData1.append('name_ar', this.name_ar);
        formData1.append('description_en', this.description_en);
        formData1.append('description_ar', this.description_ar);
        formData1.append('price', this.price);
        formData1.append('category_id', this.catigory);
        formData1.append('quantity', this.quantity);
        formData1.append('shipping_weight', this.shipping_weight);
        formData1.append('out_of_stock', this.out_of_stock ? 1 : 0);
        formData1.append('is_visiable', this.is_visiable ? 1 : 0);
        formData1.append('discount', this.discount);
        formData1.append('discount_type', this.discount_type);
        formData1.append('sku', this.sku);
        formData1.append('tags[]', this.tags);

        // Append images if present
        if (this.images && this.images.length > 0) {
          this.images.forEach((image) => {
          formData1.append('images[]', image.file);
          });
        }

        // Append variations if present
        if (this.sizeColor && this.sizeColor.length > 0) {
          this.sizeColor.forEach((variation, index) => {
            formData1.append(`variations[${index}][price]`, variation.price);
            formData1.append(`variations[${index}][code]`, variation.code);
            formData1.append(`variations[${index}][quantity]`, variation.quantity);
            formData1.append(`variations[${index}][attribute_value_ids]`, variation.attribute_value_ids);
          });
        }

        // Get product ID from route
        const id = this.$route.params.id;

        // Make the axios request
        await axios.post(`/products/${id}`, formData1);
        
        this.snackbarMessage = 'the product edited  successfully';
        this.snackbarColor = 'success';
        this.snackbar = true;
        console.log('Product added successfully');
      } catch (error) {
        console.error('Error adding product:', error);
      } finally {
        this.loading = false; // Ensure loading is set to false after the request completes
      }
    },
    async getData() {
    const id = this.$route.params.id;

    const res = await axios.get(`/products/${id}`);
    this.data = res.data.data.product;
    console.log(this.data);
    this.variations = res.data.data.product.variations;
    const data = this.data;
          // Add the item to the sizeColor array
          this.variations.forEach(variation => {
    const sizeAttribute = variation.attribute_values.find(attr => attr.atrribute.name === 'المقاس');
    const colorAttribute = variation.attribute_values.find(attr => attr.atrribute.name === 'لون');

    this.sizeColor.push({
        id: variation.id, 
        code: variation.code, 
        color: colorAttribute ? colorAttribute.name : '', 
        size: sizeAttribute ? sizeAttribute.name : '', 
        quantity: variation.quantity,
        price: variation.price
    });
});
    // Assigning the value of data.out_of_stock directly to this.product.out_of_stock
        this.name_en= data.name_en;
        this.description_en= data.description_en;
        this.name_ar= data.name_ar;
        this.description_ar= data.description_ar;
        this.category_id= data.category.id;
        this.price= data.price;
        this.discount= data?.discount;
        this.discount_type= 'percentage';
        this.tags= data?.tags;
        this.sku= data.sku;
        this.shipping_weight = data.shipping_weight;
        this.quantity= data.quantity;
        this.shipping_weigh= data.shipping_weight;
        this.imgUrl= data.images;
        this.is_visiable= data.is_visiable === "1" ? true : false;
        this.out_of_stock= data.out_of_stock === "1" ? true : false;
        // Convert imgUrl array to images array
        this.images = this.imgUrl.map(imageUrl => {
          return { url: imageUrl };
        });
},
async getSubCatigory() {
      let page = 1;
      let hasNextPage = true;
      while (hasNextPage) {
        const id = localStorage.getItem('category_id');
        const res = await axios.get(`/categories?page=${page}&parent_id=${id}`);
        const subCategories = res.data.data;
        // If the sub-categories array is empty or null, there are no more pages
        if (!subCategories || subCategories.length === 0) {
          hasNextPage = false;
        } else {
          this.subCategories.push(...subCategories); // Add the fetched sub-categories to the existing array
          page++; // Move to the next page
        }
      }
    },
    deleteItem(index) {
      this.sizeColor.splice(index, 1);
    },
    openDialog() {
      this.dialog = true;
    },
    addItem() {
      // Check if any of the fields are empty
      if (!this.newItem.color || !this.newItem.size || !this.newItem.quantity || !this.newItem.price) {
        alert('Please fill all fields.');
        return; 
      }
      
      // Add the item to the sizeColor array
      this.sizeColor.push({
        color: this.newItem.color,
        size: this.newItem.size,
        quantity: this.newItem.quantity,
        price: this.newItem.price
      });
      
      // Close the dialog
      this.dialog = false;
      
      // Clear newItem fields for next entry
      this.newItem.color = '';
      this.newItem.size = null;
      this.newItem.quantity = null;
      this.newItem.price = null;
    
  },

    cancel() {
    this.product = {}; 
    this.sizeColor=[];
    this.$router.back();
  },
  triggerFileInput() {
      // Find the file input element by its ID
      const fileInput = document.getElementById('fileInput');
      if (fileInput) {
        // Programmatically trigger the click event of the file input
        fileInput.click();
      } else {
        console.error('File input element not found.');
      }
    },
    handleFileChange(event) {
      this.imgUrl='';
      const files = event.target.files; 
      if (files && files.length > 0) {
        this.images = []; // Clear existing images array before adding new ones
    
        // Iterate over each file
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

        // Check if the file type is allowed
        if (!this.isFileAllowed(file)) {
        alert('File type not allowed. Please upload an image of type: png, jpeg or svg');
        continue; // Skip to the next file
      }

          const imageUrl = URL.createObjectURL(file); 
          this.images.push({ file: file, url: imageUrl }); 
        }
      }
    },
    isFileAllowed(file) {
          const allowedTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
          return allowedTypes.includes(file.type);
        },
    removeImage(index) {
          this.images.splice(index, 1);
        },

  }

}
</script>