<template>
  <AdminLayout>
    <v-container class="main">
      <v-row>
        <v-col cols="12" md="9"> 
          
            <div class="d-flex justify-space-between mt-5  mr-3">
              
              <p class=" align-self-start text-h4 mt-3 ml-3 font-weight-bold">Store Info</p>
              <v-spacer></v-spacer>

            </div>
          
        </v-col>
        <v-col cols="3" md="3"> 
                <v-select :label="$t('PLANS')" v-model="selectedplan"  variant="outlined" :items="plan" item-title="name" item-value="id" width="100%" no-data-text="No data available" ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"> 
          <v-card>
            <div class="d-flex flex-row bg-white text-center" style="width: 96%;">

              <div class="align-self-start flex-grow-1 mt-2" style="color: black;font-weight: bold;">
                store ID #{{ $route.params.id }}
              </div>
              <v-spacer></v-spacer>
              <div class="align-self-end">
                <v-btn :loading="loading" class="bg-black mt-1 mr-2 mb-1" height="30"  @click="modify()" rounded>Modify</v-btn>
              </div>

            </div>
          </v-card>
        </v-col>
      </v-row>
        <!-- lift side -->
      <v-row class="mt-3">
        <v-col cols="12" md="2" class="d-flex justify-center mt-3 " style="background-color: #F5F6FA;">
          <div class="d-flex flex-column" style="gap: 10px;margin-top:-10px;">


            <!-- seller info  -->
            <v-card class="d-flex flex-column " height="fit-content" width="180" color="white">
<h2 class="text-h6 d-flex justify-center align-center">store info</h2>
<v-col class=" d-flex flex-row"> <v-text-field v-model="seller.store_name" label="store" ></v-text-field> </v-col>
<v-col class=" d-flex flex-row"> <v-text-field v-model="seller.first_name" label="first_name" ></v-text-field> </v-col>
<v-col class=" d-flex flex-row"> <v-text-field v-model="seller.last_name" label="last_name" ></v-text-field> </v-col>
<v-col class=" d-flex flex-row"><v-text-field v-model="seller.phone" label="phone" ></v-text-field> </v-col>
<v-col class=" d-flex flex-row"><v-text-field v-model="seller.email" label="email" ></v-text-field> </v-col>
<v-col class=" d-flex flex-row"><v-text-field v-model="seller.description" label="description" ></v-text-field> </v-col>

</v-card>

            <!-- status -------------------------------------------- -->
            <v-card class="d-flex flex-column "  height="fit-content" width="180" color="white">
<h2 class="text-h6 d-flex justify-center align-center">Status</h2>
<v-col class="d-flex flex-column " >

  <span class="text-caption font-weight-bold" >products count</span> {{ seller.products_count }}
  <span class="text-caption font-weight-bold" >sellers count</span> {{ seller.sellers_count }}




</v-col>

            </v-card>
          </div>
        </v-col>
        <!-- right side , list of sellers on the order -->
        <v-col cols="12" md="10" class="mt-3 bg-white">
          <v-row>
            <v-col cols="12"> 

              <v-card>
  <v-row class="d-flex flex-row justify-space-between align-center">
    <v-col cols="auto"> 
      <div class="ml-9 mt-4 mb-3" style="width: 100px;">
        <v-avatar class="img-clickable" style="height: fit-content; width: fit-content;">
            <img height="80px" width="90px"  :src="seller.image" cover @click="triggerFileInput" >
            <input type="file" ref="fileInput" style="display: none;" @change="handleImageUpload" />

          </v-avatar>
      </div>
    </v-col>
    <v-col cols="auto" > 
      <v-card-title>
        {{ seller.name }}
      </v-card-title>
      <v-card-subtitle>
        catigory : {{ category.name }}
      </v-card-subtitle>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  <!-- cards------------------------ -->
  <v-row>
    <v-card class="card" height="150" width="190">
          <div class="d-flex align-center justify-space-between">
            <div> 
              <v-card-subtitle class="mt-4 ml-3">plan name </v-card-subtitle>
              <v-card-title class="text-h4 mt-7 ml-7">{{ seller.plan_name }}</v-card-title>
            </div>
            <v-avatar size="50" class="bg-amber-accent-1 mr-3">
              <img alt="Icon" :src="icons[1].url">
            </v-avatar>
          </div>
        </v-card>
    <v-card class="card" height="150" width="190">
          <div class="d-flex align-center justify-space-between">
            <div> 
              <v-card-subtitle class="mt-4 ml-3">total rating </v-card-subtitle>
              <v-card-title class="text-h4 mt-7 ml-7">{{ seller.total_rating }}</v-card-title>
            </div>
            <v-avatar size="50" class="bg-amber-accent-1 mr-3">
              <img alt="Icon" :src="icons[1].url">
            </v-avatar>
          </div>
        </v-card>
        <v-card class="card" height="150" width="190">
          <div class="d-flex align-center justify-space-between">
            <div> 
              <v-card-subtitle class="mt-4 ml-3">total sales </v-card-subtitle>
              <v-card-title class="text-h4 mt-7 ml-7">{{ seller.total_sales }}</v-card-title>
            </div>
            <v-avatar size="50" class="bg-amber-accent-1 mr-3">
              <img alt="Icon" :src="icons[1].url">
            </v-avatar>
          </div>
        </v-card>
        <v-card class="card" height="150" width="190">
          <div class="d-flex align-center justify-space-between">
            <div> 
              <v-card-subtitle class="mt-4 ml-3">total orders </v-card-subtitle>
              <v-card-title class="text-h4 mt-7 ml-7">{{ seller.total_orders}}</v-card-title>
            </div>
            <v-avatar size="50" class="bg-amber-accent-1 mr-3">
              <img alt="Icon" :src="icons[0].url">
            </v-avatar>
          </div>
        </v-card>
        <v-card class="card" height="150" width="190">
          <div class="d-flex align-center justify-space-between">
            <div> 
              <v-card-subtitle class="mt-4 ml-3">total view </v-card-subtitle>
              <v-card-title class="text-h4 mt-7 ml-7">{{ seller.views}}</v-card-title>
            </div>
            <v-avatar size="50" class="bg-amber-accent-1 mr-3">
              <img alt="Icon" :src="icons[3].url">
            </v-avatar>
          </div>
        </v-card>
  </v-row>
  <!-- sales details ------------------------ -->
  <v-col class="ml-4 " cols="12" sm="12"> 
      <v-card>
        <v-card-text>
          <v-table>
            <thead style="background-color: #979797;color: black;"> 
              <tr>
                <th> {{ $t("ID") }}</th>
                <th> {{ $t("Seller Name") }}</th>
                <th> {{ $t("total quantity") }}</th>
                <th> {{ $t("total_price") }}</th>
                <th> {{ $t("Date-time") }}</th>
                <th> {{ $t("user address") }}</th>
                
                <th > {{ $t("Status") }}</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orders" :key="item.name">
                <td>{{ item.id }}</td>
                <td>{{ item.seller.name }}</td>
                <td>{{ item.total_quantity }}</td>
                <td>{{ item.total_price }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.user_address.city }}/{{ item.user_address.street }}</td>
                <td>
                  <v-chip class="d-flex justify-center align-center" :style="{ 'background-color': getStatusColor(item.status), width: 'fit-content', 'text-align': 'center' }">{{ $t(item.status) }}</v-chip>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- products---------------------------- -->
    <!-- <v-row>
        <v-col cols="12" md="9"> 
            <div class="d-flex justify-space-between mt-5  mr-3">
              <p class=" align-self-start text-h4 mt-3 ml-3 font-weight-bold">products </p>
              <v-spacer></v-spacer>
            </div>
        </v-col>
</v-row> -->
</v-card>



        </v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </v-container>
  </AdminLayout>
</template>
<script>
import AdminLayout from '@/components/global/adminLayout.vue'
import axios from 'axios';


export default {
  components: { AdminLayout  },
  data(){
    return{
      selectedplan:'',
      plan:[],
      show: false,
      loading: false,
      imageFile: null,
      seller: {
        first_name: '',
        last_name: '',
        store_name: '',
      phone: '',
      email: '',
      description: '',
    },
      products: [],
      category: [],
      icons: [
        { url: require('@/assets/icons/Icon.png'), case: 'box' },
        { url: require('@/assets/icons/Iconchart.png'), case: 'chart' },
        { url: require('@/assets/icons/Iconsoon.png'), case: 'soon' },
        { url: require('@/assets/icons/Iconuser.png'), case: 'person' },
      ],
      orders:[],

    }
  },
created(){
  this.getInfo();
  this.getOrders();
  this.getPlans();

},
methods:{
  async getPlans() {
      const res = await axios.get('/packages?page=1');
      this.plan = res.data.data;
    },
  async getOrders() {
      const res = await axios.get('/orders');
      this.orders = [...res.data.data];
 
    },
  async getInfo() {
    const id = this.$route.params.id;
      const res = await axios.get(`/sellers/${id}`);
      this.seller = res.data.data.seller;
      this.category = this.seller.category;
      this.products = this.seller.products;

    },
    getStatusColor(status) {
    switch (status) {
        case 'pending':
            return '#FFD166'; // Yellow
        case 'delivered':
            return '#06D6A0'; // Green
        case 'failed':
            return '#EF476F'; // Red
        case 'on_the_way':
            return '#118AB2'; // Blue
        case 'returned':
            return '#FF851B'; // Orange
        default:
            return '#ced4da'; // Light Grey
    }
},
async modify(){
  this.loading = true
          try{
            {
              // const name= this.seller.name;
              const formData = new FormData();
              formData.append('_method', 'PUT');
              formData.append('first_name', this.seller.first_name);
              formData.append('last_name ', this.seller.last_name);
              formData.append('store_name', this.seller.name);
              formData.append('phone', this.seller.phone);
              formData.append('email', this.seller.email);
              formData.append('description', this.seller.description);
              formData.append('image', this.imageFile); 
              formData.append('package_id', this.selectedplan); 

              const id = this.$route.params.id 
              await axios.post(`/sellers/${id}`, formData);
              this.loading = false;
              alert('updated  successfully !')
            }
          }catch (e) {
            alert ('Error logging in:', e )
this.loading=false;
  }
},
handleImageUpload(event) {
      const file = event.target.files[0]; // Get the first file from the event
      if (file) {
        this.imageFile = file;
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
}
}
</script>

<style scoped>
.main {
  background-color: #F5F6FA;
}
.rounded1 {
  border-radius: 10px;
}
.img-clickable:hover{
  cursor: pointer;
}
</style>
