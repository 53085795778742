import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from '@/i18n'
import global from './assets/global.css';
import { createPinia } from 'pinia';
import './axios'

//Emitter config 
import mitt from "mitt";
const Emitter = mitt();

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import "@mdi/font/css/materialdesignicons.css";
import { pl, zhHans } from 'vuetify/locale'
const vuetify = createVuetify({
  components,
  directives,
  rtl: true,
  locale: {
    locale: 'zhHans',
    fallback: 'i18n',
    messages: { zhHans, pl, i18n },
  },
});

createApp(App).use(router).use(createPinia()).use(global).use(vuetify).use(i18n).provide("Emitter", Emitter).mount('#app')
