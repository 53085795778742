<template>
  <v-app>
    <v-main>
      <v-container>
        <h1>Test Color Picker</h1>
        <v-color-picker v-model="newItem.code"></v-color-picker>
        <p>Selected Color Code: {{ newItem.code }}</p>
        <v-btn @click="logColorCode">Log Color Code</v-btn>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'TestPage',
  data() {
    return {
      newItem: {
        code: '#000000', // Default color
      },
    };
  },
  methods: {
    logColorCode() {
      console.log(this.newItem.code);
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
