<template>
  <main-Layout style="width: 100%;">
    <!-- Section Title -->
    <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3">
        {{ $t("Inventory") }}
      </p>
    </div>
    <v-col>
      <v-btn variant="tonal" rounded to="/newProduct">
        <v-icon icon="mdi-plus" size="x-large"></v-icon>
        &nbsp;&nbsp; New Product
      </v-btn>
    </v-col>
    <v-col>
      <v-text-field
        v-model="searchPoduct"
        label="Search"
        outlined
        placeholder="Type to search..."
        >
      </v-text-field>
      <v-list v-if="search.length > 0">
        <v-list-item v-for="product in search" :key="product.id" @click="selectProduct(product)">
          <v-list-item-content>
            <v-list-item-title>{{ product.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col>
      <v-btn rounded variant="tonal" @click="stockStatus = 'in_stock'" :color="stockStatus === 'in_stock' ? 'primary' : ''">In Stock</v-btn>
      <v-btn variant="tonal" @click="stockStatus = 'out_of_stock'" :color="stockStatus === 'out_of_stock' ? 'primary' : ''">Out of Stock</v-btn>
    </v-col>
    <v-container class="mt-5" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <v-row class="d-flex align-content-center justify-space-between flex-column" v-for="(item, i) in filtredProducts" :key="i">
        <!-- Card to display selected product -->
        <v-col v-if="selectedProduct">
          <v-card
            class="cards w-100 mb-3 d-flex flex-row align-center justify-space-between"
            :to="{ name: 'details', params: { id: item?.id } }"
            color="#F5F6FA"
            height="70"
            v-if="selectedProduct.id == item.id"
          >
            <img :src="item.images[0]" style="height: 100%;" />
            <span>{{ item.name }}</span>
            <span>price : {{ item.price }} jd</span>
            <span>Amount :{{ item.quantity }}</span>
            <span v-if="item.sku">SKU : '{{ item.sku }}'</span>
          </v-card>
        </v-col>
        <v-card
          class="cards w-100 mb-3 d-flex flex-row align-center justify-space-between"
          :to="{ name: 'details', params: { id: item?.id } }"
          color="#F5F6FA"
          height="60"
          :loading="loading"
          v-else
        >
          <img :src="item.images" style="height: 100%;" />
          <span>{{ item.name_en }}</span>
          <span>{{ item.price }}JOD</span>
          <span>Amount:{{ item.quantity }}</span>
          <span v-if="item.sku">SKU:{{ item.sku }}</span>
        </v-card>
      </v-row>
      <v-row class="d-flex flex-row justify-center align-center" style="gap: 20px;">
        <v-btn id="back" @click="back" :loading="loading" color="black">back</v-btn>
        <span class="font-weight-bold">{{ page }}</span>
        <v-btn id="next" @click="next" :loading="loading" color="black">next</v-btn>
      </v-row>
    </v-container>
  </main-Layout>
</template>

<!--

<v-col >
  <v-select disabled v-model="filter" :items="items" label="Filter" class="text-black" item-title="name"></v-select>
</v-col>

-->
<script>
import MainLayout from '@/components/global/mainLayout.vue';
import axios from 'axios';

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      items: [],
      links: [],
      searchPoduct: '',
      search: [],
      filter: '',
      loading: false,
      page: 1,
      id: localStorage.getItem('id'),
      selectedProduct: '',
      stockStatus: 'in_stock',
    };
  },
  watch: {
  stockStatus() {
    this.fetchData(); // Call the same fetch method but with different query params based on stock status
  },
  },
  computed: {
    filtredProducts() {
      return this.items.filter((product) => {
        const bigLatter = this.searchPoduct.toUpperCase();
        const smallLatter = this.searchPoduct.toLowerCase();
        const list = product.name.toLowerCase();
        return list.match(smallLatter || bigLatter);
      });
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    back() {
      if (this.page != 0) this.page--;
      this.fetchData();
    },
    next() {
      this.page++;
      this.fetchData();
    },
    async fetchData() {
    try {
      this.loading = true;
      const outOfStockParam = this.stockStatus === 'out_of_stock' ? '&out_of_stock=1' : '';
      const response = await axios.get(`/products?page=${this.page}&seller_id=${this.id}${outOfStockParam}`);
      
      this.items = [...response.data.data];
      this.links = response.data.links;

      this.updatePaginationButtons();
      this.loading = false;
    } catch (error) {
      console.error('Error fetching data:', error);
      this.loading = false;
    }
  },

    async searchProducts() {
      try {
        this.loading = true;
        const response = await axios.get(`/products/search?key=${this.searchPoduct}`);
        this.searchResults = response.data.data;
        this.loading = false;
      } catch (error) {
        console.error('Error searching products:', error);
      }
    },
    selectProduct(product) {
      this.selectedProduct = product;
      this.searchPoduct = ''; // Clear search field
      this.search = []; // Clear search results
    },
  },
};
</script>

<style scoped>
#back[disabled],
#next[disabled] {
  opacity: 0.5; /* Adjust the opacity to make the buttons appear disabled */
  cursor: not-allowed; /* Change the cursor to indicate that the button is disabled */
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.text-color {
  color: #ff5733; /* Change this to the desired color */
}
</style>
