<template >
  <v-form @submit.prevent="submit" class="centered-container">
    
    <v-card
      class="w-75 pa-12"
      :style="cardStyles"
      rounded="xl"
      :loading="loading"
    >
      <v-text-field
        class="mb-4"
        density="compact"
        placeholder="Email"
        prepend-inner-icon="mdi-email-outline"
        v-model="email" 
        :rules="emailRules"
        variant="outlined"
      >
      </v-text-field>
      
      
      <v-text-field
        class="mb-4"
        :append-inner-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
        :type="visible ? 'text' : 'password'"
        density="compact"
        placeholder="password"
        prepend-inner-icon="mdi-lock-outline"
        variant="outlined"
        v-model="password" 
        :rules="passwordRules"
        @click:append-inner="visible = !visible"
      >
      </v-text-field>

      <v-btn
        type="submit"
        class="mb-8"
        color="blue"
        size="large"
        variant="tonal"
        block
        :loading="loading"
      >
        Log In
      </v-btn>

      <v-card-text class="text-medium text-caption text-center" v-if="error">
        {{ error }}
      </v-card-text>

      <v-card-text class="text-center">
        <a
          class="text-blue text-decoration-none"
          href="/register"
          rel="Register"
        >
          Sign up now <v-icon icon="mdi-chevron-right"></v-icon>
        </a>
      </v-card-text>
      <!-- // Forget Password //
      
      <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
        <a
          class="text-caption text-decoration-none text-blue"
          href="#"
          rel="noopener noreferrer"
          >
          Forgot login password ? Click
        </a>
      </div>  

      -->

    </v-card>
    
  </v-form>
</template>


<script>
import axios from 'axios';

export default 
{
  name: "LoginForm",
  components:{},
  computed:{},
  data() {
    return {
      email: '',
      emailRules: [
        (v) => !!v || "Email is required",  
        (v) => /.+@.+\..+/.test(v) || "Enter a valid email address"  // Validates email format
      ],
      password: '',
      passwordRules: [
        value => !!value || 'Password is required',
      ], 
      visible: false,
      loading: false,
      error:'',
    };
  },

   methods: {
    async submit() {
      this.loading=true;
      try {
      const res = await axios.post('/auth/login', {
        email: this.email,
        password: this.password
      });
      
      this.loading=false;

      const userData = res.data.data.user;
      const accessToken = res.data.data.access_token;
    
      localStorage.setItem('token', accessToken);    
      localStorage.setItem('id', userData.id);
      localStorage.setItem('first_name', userData.first_name);
      localStorage.setItem('image', userData.image);
      localStorage.setItem('email', userData.email);
      localStorage.setItem('phone', userData.phone);
      localStorage.setItem('store_name', userData.store_name);
      localStorage.setItem('role', userData.role);
      localStorage.setItem('category_id', userData.category_id);
      localStorage.setItem('locale', 'en');  

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;

      this.loading=false;

      if (userData.role === 'seller') 
      {
        this.$router.push('/seller');
      } 
      else if (userData.role === 'admin') 
      {
      this.$router.push('/admin');
      } 
      this.loginUser(userData);
      } catch (e) {
      console.error('Error logging in:', e);
      console.log(e);
      
      this.error="Invaild email or password "
      this.loading=false;
     }
  },}
};
</script>



<style scoped>
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}
</style>
  