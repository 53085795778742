<template>
  <main-Layout>
    <div class="d-flex justify-space-between mt-2 mb-6" :dir="$i18n.locale === 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3">
        {{ $t("Orders") }}
      </p>
    </div>
        <v-col>
          <v-text-field
          v-model="searchPoduct"
          @input="searchProduct"
          :label="$t('Search')"
          outlined
          placeholder="Type to search..."
        ></v-text-field>
        </v-col>
        <v-col>
          <v-table :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
            <thead style="background-color:#F5F6FA; color: black;">
              <tr>
                <th> {{ $t("Orders") }}</th>
                <th> {{ $t("Status") }}</th>
                <th> {{ $t("Date") }}</th>
                <th> {{ $t("Total") }}</th>
                <th> {{ $t("Quantity") }}</th>
                <th> {{ $t("Customer") }}</th>
                <th> {{ $t("Action") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filtredOrder" :key="item.name" >
                <td>#{{ item.id }} </td>
                <td >
                  <v-chip class="d-flex justify-center align-center"
                    :style="{ 'background-color': getStatusColor(item.status), width: 'fit-content', 'text-align': 'center' }">
                    {{ $t(item.status) }}
                  </v-chip>
                </td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.total_price }}</td>
                <td>{{ item.total_quantity }}</td>
                <td>{{ item.customer.first_name }} {{ item.customer.last_name }}</td>
                <td>
                  <v-btn variant="tonal" :to="{ name: 'orderDetails', params: { id: item?.id } }" >
                    {{ $t("Details") }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
  </main-Layout>
</template>

<script>
import MainLayout from '@/components/global/mainLayout.vue'
import axios from 'axios';

export default {
  components: {
    MainLayout
  },
          
  computed:{ 
    filtredOrder : function (){
      return this.orders.filter((order)=> {
        return  (
          order.id.toString().includes(this.searchPoduct) || // Search by ID
          order.customer.first_name.toLowerCase().includes(this.searchPoduct) // Search by name
  );
      });
    }
  },
  data() {
    return {
      orders: [],
      searchPoduct:'',
    }
  },
  created() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      const res = await axios.get('/orders');
      this.orders = [...res.data.data];
    },
    getStatusColor(status) {
    switch (status) {
        case 'pending':
            return '#FFD166'; // Yellow
        case 'delivered':
            return '#06D6A0'; // Green
        case 'failed':
            return '#EF476F'; // Red
        case 'on_the_way':
            return '#118AB2'; // Blue
        case 'returned':
            return '#FF851B'; // Orange
        default:
            return '#ced4da'; // Light Grey
    }
},
  }
}
</script>

<style>
.top-text {
  font-weight: bold;
  padding: 10px;
  height: 70px;
  background: linear-gradient(to right, #fff 0%, #c1c0c0 100%); /* Default gradient */
}

[dir="rtl"] .top-text {
  background: linear-gradient(to left, #c1c0c0 0%, #fff 100%); /* Adjusted gradient for RTL */
}
</style>
