<template>
  <v-snackbar v-model="internalSnackbar" :color="snackbarColor" top right>
    <v-row class="d-flex align-center">
      <v-col cols="auto">
        {{ snackbarMessage }}
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn icon @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBar",
  props: ["snackbarColor", "snackbarMessage", "snackbar"],
  data() {
    return {
      internalSnackbar: false // Use internal state to control the snackbar
    };
  },
  watch: {
    snackbar(newVal) {
      this.internalSnackbar = newVal; // Update internal state when prop changes
    }
  },
  methods: {
    closeSnackbar() {
      
      this.$emit("close"); //on the button : @close="snackbar = false"
    }
  }
};
</script>
