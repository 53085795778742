import admin from '../views/admin/adminHome.vue'
import products from '../views/admin/productsView.vue'
import orderLists from '../views/admin/orderLists.vue'
import orderAdminDetails from '../views/admin/orderAdminDetails.vue'
import admissions from '../views/admin/admissionsView.vue'
import stores from '../views/admin/storesView.vue'
import storeInfo from '../views/admin/storeInfo.vue'
import category from '../views/admin/categoryView.vue'
import adsView from '../views/admin/adsView.vue'
import team from '../views/admin/teamView.vue'
import customers from '../views/admin/customersView.vue'
import productInfo from '../views/admin/productInfo.vue'
import plansManagment from '../views/admin/plansManagment.vue'
import attributes from '../views/admin/attributeManagment.vue'




const adminRoutes =[
  {
    path: '/admin',
    name: 'admin',
    component: admin,

  },
  {
    path: '/products',
    name: 'products',
    component: products
  },
  {
    path: '/orderLists',
    name: 'orderLists',
    component: orderLists
  },
  {
    path: '/orderAdminDetails/:id',
    name: 'orderAdminDetails',
    component: orderAdminDetails
  },
  {
    path: '/admissions',
    name: 'admissions',
    component: admissions
  },
  {
    path: '/stores',
    name: 'stores',
    component: stores
  },
  {
    path: '/store-info/:id',
    name: 'storeInfo',
    component: storeInfo
  },
  {
    path: '/category',
    name: 'category',
    component: category
  },
  {
    path: '/ads',
    name: 'ads',
    component: adsView
  },
  {
    path: '/team',
    name: 'team',
    component: team
  },
  {
    path: '/plans-managment',
    name: 'plansManagment',
    component: plansManagment
  },
  {
    path: '/customers',
    name: 'customers',
    component: customers
  },
  {
    path: '/product-info/:id',
    name: 'productInfo',
    component: productInfo
  },
  {
    path: '/attributes',
    name: 'attributes',
    component: attributes
  },

];
export default adminRoutes;