<template>
<v-alert type="success" closable>
  {{ res }}
  
  </v-alert>
</template>
<script>

export default{
  name:"responseView",
  props:[ 'res']
}
</script>