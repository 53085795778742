<template>
  <AdminLayout>
    <v-container class="main" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <v-row>
        <v-col cols="12" md="9"> 
          
            <div class="d-flex justify-space-between mt-5  mr-3" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
              
              <p class=" align-self-start text-h4 mt-3 ml-3 font-weight-bold"> {{ $t("Order Info") }}</p>
              <v-spacer></v-spacer>
              <v-col class="align-self-end" cols="3">

                <v-text-field
                  class="bg-white rounded1"
                  :label="$t('Search')" 
                  hide-details
                  color="black"
                ></v-text-field>
              </v-col>
            </div>
          
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"> 
          <v-card>
            <div class="d-flex flex-row bg-white text-center" style="width: 96%;" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">

              <div class="align-self-start flex-grow-1 mt-2" style="color: black;font-weight: bold;">
                Order ID #{{ $route.params.id }}
              </div>
              <v-spacer></v-spacer>
              <div class="align-self-end mx-3" >
                <v-btn :loading="loadingCancel" class="bg-red-darken-1 mt-1 mr-2 mb-1" height="30" @click="cancelOrder('cancelled')" rounded> {{ $t("Cancel") }}</v-btn>
                <v-btn :loading="loadingCancel" class="bg-green-darken-1 mt-1 mb-1 mx-2" height="30" @click="cancelOrder('delivered')" rounded> {{ $t("delivered") }}</v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
        <!-- lift side -->
      <v-row class="mt-3">
        <v-col cols="12" md="2" class="d-flex justify-center mt-3 " style="background-color: #F5F6FA;">
          <div class="d-flex flex-column" style="gap: 10px;margin-top:-10px;">


            <!-- customer info  -->
            <v-card class="d-flex flex-column " height="fit-content" width="180" color="white" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
<h2 class="text-h6 d-flex justify-center align-center"> {{ $t("Customer info") }}</h2>
<v-col class=" d-flex flex-row"><span class="text-caption font-weight-bold"> {{ $t("Phone") }}:</span> <span class="text-caption">{{ customer.phone }}</span> </v-col>
<v-col class=" d-flex flex-row"><span class="text-caption font-weight-bold"> {{ $t("Phone") }}2 :</span> <span class="text-caption">{{ customer.alter_phone }}</span> </v-col>
<v-col class=" d-flex flex-row"><span class="text-caption font-weight-bold"> {{ $t("First name") }} :</span> <span class="text-caption">{{ customer.first_name }}</span> </v-col>
<v-col class=" d-flex flex-row"><span class="text-caption font-weight-bold"> {{ $t("Last name") }}:</span> <span class="text-caption">{{ customer.last_name }}</span> </v-col>
<v-col class=" d-flex flex-row"><span class="text-caption font-weight-bold"> {{ $t("Email") }}:</span> <span class="text-caption">{{ customer.email }}</span> </v-col>
<v-col>
  <GoogleMap api-key="AIzaSyAGJ1CPhFVTEbprPtvpz_1svPHURNBIA1M"
    style="width: 100%; height: 200px" :center="center"
      :zoom="13" >
      <Marker :options="{ position: center }" />
    </GoogleMap>
</v-col>
<v-col class=" d-flex flex-row"><span class="text-caption font-weight-bold">{{ $t("City") }}:</span> <span class="text-caption">{{ user_address.city }}</span> </v-col>
<v-col class=" d-flex flex-row"><span class="text-caption font-weight-bold">{{ $t("nearby") }}:</span> <span class="text-caption">{{ user_address.nearby }}</span> </v-col>
<v-col class=" d-flex flex-row"><span class="text-caption font-weight-bold">{{ $t("Address") }}:</span> <span class="text-caption">{{ user_address.street }}</span> </v-col>
<v-col><h2>{{ data.total_price }} JOD</h2></v-col>


            </v-card>

            <!-- delivary -------------------------------------------- -->
            <v-card class="d-flex flex-column "  height="fit-content" width="180" color="white">
<h2 class="text-h6 d-flex justify-center align-center"> {{ $t("Delivary") }}</h2>
<v-col class="d-flex flex-column " >

  <span class="text-caption font-weight-bold" > {{ $t("Share the orderdetails in what's app") }} </span> 

            <v-btn
            class="mt-9"
              @click="shareViaWhatsapp()"
              :text= "$t('Share')" 
              rounded
              color="black"
            ></v-btn>


</v-col>

            </v-card>
          </div>
        </v-col>
        <!-- right side , list of sellers on the order -->
        <v-col cols="12" md="10" class="mt-3 bg-white">
          <v-row>
            <v-col cols="12"> 

              <v-card>
  <v-row class="d-flex flex-row justify-space-between align-center" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
    <v-col cols="auto"> 
      <div class="ml-9 mt-4 mb-3" style="width: 100px;">
        <v-avatar style="height: fit-content; width: fit-content;">
            <img height="80px" width="90px"  :src="seller.image" cover >
          </v-avatar>
      </div>
    </v-col>
    <v-col cols="auto" > 
      <v-card-title>
        {{ seller.name }}
      </v-card-title>
      <v-card-subtitle>
        Id: {{ seller.id }}
      </v-card-subtitle>
    </v-col>
    <v-col cols="auto" >
      <v-btn :append-icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'" @click="show = !show" variant="text">{{ $t("View") }}</v-btn>
    </v-col>
  </v-row>

  <v-expand-transition>
    <div v-show="show">
      <v-divider></v-divider>
<!-- table  -->
<v-card :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
  <v-table>
  <thead style="background-color: #979797;color: black;">
    <tr>
      <th>{{ $t("ID ") }}</th>
      <th> {{ $t("Image") }}</th>
      <th>{{ $t("Product name") }}</th>
      <th>{{ $t("Category") }}</th>
      <th>{{ $t("Price") }}</th>
      <th>{{ $t("Quantity") }}</th>
      <th>{{ $t("Size") }}</th>
      <th>{{ $t("Color") }}</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="item in items" :key="item.id">
      <td>{{ item.id }}</td>
      <td><img :src="item.product.images" height="80" width="60"/></td>
      <td>{{ item.product.name }}</td>
      <td>{{ item.product.category.name }}</td>
      <td>{{ item.price }}</td>
      <td>{{ item.quantity }}</td>
      <td v-if="item.product.variation && item.product.variation.attribute_values && item.product.variation.attribute_values.atrribute.name === 'المقاس'">
        {{ item.product.variation.attribute_values.name }}
      </td>
      <td v-if="item.product.variation && item.product.variation.attribute_values && item.product.variation.attribute_values.atrribute.name === 'لون'">
        <div :style="{ backgroundColor: item.product.variation.attribute_values.code, height: '33px', width: '33px', borderRadius: '50%' }"></div>
      </td>
    </tr>
  </tbody>
</v-table>

          </v-card>
    </div>
  </v-expand-transition>
</v-card>



        </v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </v-container>
  </AdminLayout>
</template>
<script>
import AdminLayout from '@/components/global/adminLayout.vue'
import { GoogleMap, Marker } from 'vue3-google-map';
import axios from 'axios';


export default {
  components: { AdminLayout , GoogleMap, Marker  },
  data(){
    return{
      show: false,
      loadingCancel: false,
      center: { "lat": 32.16551561842881, "lng": 35.85163020532191 },
      data: [],
      items: [],
      customer: [],
      seller: [],
      user_address: [],
      delivary:{name:'',phone:''}
    }
  },
created(){
  this.getInfo();
},
methods:{
async  cancelOrder(status)
  {
    alert(status)
    const id = this.$route.params.id ;
    this.loadingCancel=true;
    try{
      await axios.post(`/orders/${id}`,{
        _method : 'PUT',
        status : status
      })
      alert('cancelled' + id)
    }catch (e){
      alert(e)

    }finally{
      this.loadingCancel=false;

    }
  },
  async getInfo() {
    const id = this.$route.params.id;
      const res = await axios.get(`/orders/${id}`);
      this.data = res.data.data;
      this.customer = res.data.data.customer;
      this.user_address = res.data.data.user_address;
      this.seller = res.data.data.seller;
      this.items = res.data.data.items;
      
      //update cinter
      this.center = { lat: this.user_address.lat, lng: this.user_address.lng }; 
      console.log(this.center);

      this.customerCenter = { lat: this.user_address.lat, lng: this.user_address.lng };
      this.sellerCenter = { lat: this.seller.lat, lng: this.seller.lng };

      console.log("Locations : ",this.customerCenter, this.sellerCenter);
      console.log("coustomer Location: ", this.user_address.lat,this.user_address.lng);
      console.log("Seller Location : ",this.seller.lat,this.seller.lng);
    },
  shareViaWhatsapp() {

  const message = this.composeMessage();

  // Construct the WhatsApp URL
  const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

  // Open the WhatsApp URL in a new tab
  window.open(whatsappURL, '_blank');
  this.cancelOrder('on_the_way');
},
composeMessage() {
  let message = "📦 Order Details:\n\n";
  message += `💰Total Price: ${this.data.total_price }\n`;

  message += `📞 Customer Phone Number: ${this.customer.phone}\n`;
  message += `📞 Customer Phone Number2: ${this.customer.alter_phone}\n`;
  message += `👤 Customer Name: ${this.customer.first_name} ${this.customer.last_name}\n`;
  message += `📍 Customer Location: ${this.user_address.city}, ${this.user_address.nearby}, ${this.user_address.street}\n\n`;
  // Add a clickable link to Customer's location on Google Maps
  //const customerGoogleMapsLink = `🌍 Customer Location on Google Maps: [Open in Google Maps](https://www.google.com/maps/search/?api=1&query=${this.customer.lat},${this.customer.lang})\n`;
  const customerGoogleMapsLink = `🌍 Customer Location on Google Maps: [Open in Google Maps](https://maps.google.com/?q=${this.customer.lat},${this.customer.lang},)\n`;
  message += customerGoogleMapsLink;


  // Seller Information
  message += `🛒 Seller Name: ${this.seller.name}\n`;
  message += `📞 Seller Phone Number: ${this.seller.phone}\n`;

  // Add a clickable link to Seller's location on Google Maps
  //const sellerGoogleMapsLink = `🌍 Seller Location on Google Maps: [Open in Google Maps](https://www.google.com/maps/search/?api=1&query=${this.seller.lat},${this.seller.lang})\n`;
  const sellerGoogleMapsLink = `🌍 Seller Location on Google Maps: [Open in Google Maps](https://maps.google.com/?q=${this.seller.lat},${this.seller.lang})\n`;
  message += sellerGoogleMapsLink;

  // Loop through each item in the items array
  this.items.forEach(item => {
    message += `📌 Item ID: ${item.id}\n`;
    message += `📦 Product: ${item.product.name}\n`;
    message += `🏷️ Category: ${item.product.category.name}\n`;
    message += `💰 Price: ${item.price}\n`;
    message += `🛒 Quantity: ${item.quantity}\n`;
    
    // Check if the item has a variation and attribute values
    if (item.product.variation && item.product.variation.attribute_values) {
      item.product.variation.attribute_values.forEach(attribute => {
        if (attribute.atrribute.name === 'المقاس') {
          message += `📏 Size: ${attribute.name}\n`;
        } else if (attribute.atrribute.name === 'لون') {
          message += `🎨 Color: ${attribute.name}\n`;
        }
      });
    }

    message += "\n"; // Add a newline after each item
  });

 
  return message;
}



}
}
</script>

<style scoped>
.main {
  background-color: #F5F6FA;
}
.rounded1 {
  border-radius: 10px;
}
</style>
