<template>
  <v-container class="pa-md-12 pa-sm-6" fluid>
    <v-responsive
      class="text-center mx-auto mb-6 mb-md-12"
      max-width="700"
    >
      <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title">
        {{ $t('Unlock Premium Access for Just One Month')}}
      </h1>

      <p class="text-subtitle-1 text-medium-emphasis">
        {{ $t("Experience the full potential of our services with a monthly subscription. Enjoy access to all our premium products and exclusive features for a low monthly fee. Perfect for businesses looking to scale without long-term commitments.") }}
      </p>

    </v-responsive>
    <v-row>
      <v-col 
        cols="12" 
        md="4" 
        v-for="(sheet, index) in sheets" 
        :key="index"
        >
        <v-sheet 
          border 
          class="mx-auto pa-2 my-4" 
          max-width="400" 
          rounded="xl"
          >
          <v-col>
            <v-row class="h-100 flex-column d-flex align-center justify-center pa-4">
              <v-card border class="h-100 flex-column d-flex align-center justify-center pa-4 w-100" color="blue" rounded="xl" variant="tonal">
                <div class="text-subtitle-1 font-weight-medium mb-3 text-high-emphasis align-center">
                  {{ sheet.title }}
                </div>

                <div class="text-h3 mb-8 text-black font-weight-bold">
                  {{ sheet.price }}
                </div>

                <v-btn class="mb-5 text-none" color="blue" max-height="36" max-width="256" rounded="lg" text="Get access" variant="flat" width="100%" />

                <div class="text-caption text-medium-emphasis w-100 text-center">
                  {{ $t(sheet.description) }}
                </div>
              </v-card>
            </v-row>

            <v-row class="pa-6">
              <h2 class="text-h5 font-weight-bold mb-4">{{ sheet.productsTitle }}</h2>
              <div :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
                <p class="text-medium-emphasis mb-4">
                  {{ $t(sheet.productsDescription) }}
                </p>
              </div>
            </v-row>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
  <div class="d-flex align-center justify-center pa-8">
    <v-btn class="mb-5 text-none" color="black" max-height="36" max-width="256" rounded="lg" text="Back" variant="flat" width="100%" to="/" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      sheets: [
        {
          title: "Start with simple products",
          price: "50 JOD",
          description: "Send us an email from your store email to verify your account.",
          productsTitle: "Get 100 Products",
          productsDescription: "Gain access to 100 products in your account and upload them to your marketplace by clicking 'Get Access'. Verify your account via email to proceed."
        },
        {
          title: "Advanced Package",
          price: "100 JOD",
          description: "Send us an email from your store email to verify your account.",
          productsTitle: "Get 200 Products",
          productsDescription: "Gain access to 200 products in your account and upload them to your marketplace by clicking 'Get Access'. Verify your account via email to proceed."
        },
        {
          title: "Pro Package",
          price: "150 JOD",
          description: "Send us an email from your store email to verify your account.",
          productsTitle: "Get 300 Products",
          productsDescription: "Gain access to 300 products in your account and upload them to your marketplace by clicking 'Get Access'. Verify your account via email to proceed."
        }
      ]
    }
  }
}

</script>
