<template >
    <v-layout >
      <!-- Mobile Menu Button -->
      <v-app-bar app elevation="0">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>
        <v-toolbar-title>Eboom</v-toolbar-title>
      </v-app-bar>

      <!-- Navigation Drawer -->
      <v-navigation-drawer
        v-model="drawer"
        :right="$i18n.locale === 'arabic'"
        app
      >
        <v-list>
          <v-list-item :title="store_name" :prepend-avatar="image" :subtitle="email"></v-list-item>
        </v-list>


        <v-list dense nav>
          <v-list-item v-for="item in menuItems" :key="item.title" :prepend-icon="item.icon" :title="$t(item.title)" :to="item.route" @click="navigateTo(item.route)"></v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- Main Content Area -->
      <v-main style="width: 100% !important; height: 100% !important;" >
        <slot style="width: 100% !important; height: 100% !important;" ></slot>
      </v-main>
    </v-layout>
</template>

<script>
import img from '@/assets/images/account.png';
import axios from 'axios';

export default {
  data() {
    return {
      drawer: false,
      img: img,
      id: localStorage.getItem('id'),
      image: localStorage.getItem('image'),
      first_name: localStorage.getItem('first_name'), 
      email: localStorage.getItem('email'),
      phone: localStorage.getItem('phone'),
      store_name: localStorage.getItem('store_name'),
      menuItems: [
        { title: 'Dashboard', icon: 'mdi-home', route: '/seller' },
        { title: 'Inventory', icon: 'mdi-stocking', route: '/inventory' },
        { title: 'Orders', icon: 'mdi-details', route: '/orders' },
        { title: 'Support', icon: 'mdi-card-account-mail-outline', route: '/Contact' },
        { title: 'Plans', icon: 'mdi-airballoon', route: '/plans' },
        { title: 'Profile', icon: 'mdi-account-tie', route: '/profile' },
        { title: 'Settings', icon: 'mdi-cog', route: '/setting' },
        { title: 'Logout', icon: 'mdi-logout', route: '', action: 'logout' }
      ],
    };
  },
  methods: {
    async logout() {
      try {
        localStorage.clear();
        this.$router.push('/login');
        await axios.post('/auth/logout');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error('User is not authenticated');
        } else {
          console.error('Logout error:', error);
        }
      }
    },
    navigateTo(route) {
      if (route === '') {
        this.logout();
      } else {
        this.$router.push(route);
        this.drawer = false; // Close drawer after navigation
      }
    },
  },
};
</script>

<style scoped>
.layout{
  background-color: #F5F6FA;
}
</style>
