import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import settingView from '../views/SettingView.vue'
import LoginView from '../views/Auth/LoginView.vue'
import RegisterView from '../views/Auth/RegisrationView.vue'
import SellerHome from '../views/seller/sellerHome.vue'
import inventoryView from '../views/seller/inventoryView.vue'
import newProduct from '../views/seller/newProduct.vue'
import orders from '../views/seller/ordersView.vue'
import AboutUS from '../views/AboutUs.vue'
import Contact from '../views/ContactView.vue'
import plans from '../views/PlansView.vue'
import Profile from '../views/seller/profileView.vue'
import details from '../views/seller/detailsView.vue'
import orderDetails from '../views/seller/orderDetails.vue'
import edit from '../views/seller/editProduct.vue'
import adminRoutes from './adminRoutes' // Import the adminRoutes array
import Services from '../views/Services'


import TestPage from '../views/Test.vue'; // Ensure the path is correct


const routes = [
  // {
  //   path: '/',
  //   redirect: { name: 'seller' }  // Redirect to the login page by default
  // },
  ...adminRoutes,
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/test',
    name: 'TestPage',
    component: TestPage
  },
  {
    path: '/about',
    name: 'about',
    component: AboutUS
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/plans',
    name: 'plans',
    component: plans
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: edit
  },

  {
    path: '/newProduct',
    name: 'newProduct',
    component: newProduct
  },
  {
    path: '/details/:id',
    name: 'details',
    component: details
  },
  {
    path: '/seller',
    name: 'seller',
    component: SellerHome
  },
  {
    path: '/orderDetails/:id',
    name: 'orderDetails',
    component: orderDetails
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: inventoryView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/setting',
    name: 'setting',
    component: settingView
  },
  {
    path: '/orders',
    name: 'orders',
    component: orders
  },
  {
    path: '/Services',
    name: 'Services',
    component: Services
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
