<template>
  <AdminLayout>
    <div class="d-flex justify-space-between mt-5 mb-12" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > 
        {{ $t("All products") }}
      </p>
    </div>
    <v-col>
      <v-text-field
        v-model="searchPoduct"
        @input="searchProduct"
        label="Search"
        outlined
        placeholder="Type to search..."
        >
      </v-text-field>
    </v-col>
    <v-col> 
      <v-select 
        :label="$t('seller')"
        v-model="selectedseller"
        variant="outlined" 
        :items="seller"
        item-title="name"
        item-value="id" 
        width="100%" 
        no-data-text="No data available"
        >
      </v-select>
    </v-col>
        <v-col sm="12"> 
          <v-card :loading="loading">
            <v-card-text>
              <v-table>
                <thead style="background-color: #F5F6FA;">
                  <tr>
                    <th> {{ $t("ID") }}</th>
                    <th> {{ $t("Image") }}</th>
                    <th> {{ $t("Product name") }}</th>
                    <th> {{ $t("Store name") }}</th>
                    <th> {{ $t("Catigory") }}</th>
                    <th> {{ $t("Date-time") }}</th>
                    <th> {{ $t("Price") }}</th>
                    <th> {{ $t("Quantity") }}</th>
                    <th> {{ $t("Actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filtredProducts" :key="item.id" class="table-row-hover">
                    <td>{{ item.id }}</td>
                    <td><img  :src="item.images" height="80" width="60"/></td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.seller.name  }}</td>
                    <td>{{ item.category.name }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.quantity }}</td>
                  <td>
                    <v-btn @click="deleteItem(item.id)"><v-icon color="red" >mdi-delete</v-icon></v-btn>
                    <v-btn  :to="{ name: 'edit', params: { id: item?.id }  }"><v-icon>mdi-pencil-box-outline</v-icon> </v-btn>
                  </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>
    
    <v-dialog v-model="deleteConfirmation" max-width="500">
      <v-card>
        <v-card-title class="headline"> {{ $t("Are you sure you want to delete this item?") }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteConfirmed"> {{ $t("Yes") }}</v-btn>
          <v-btn color="primary darken-1" text @click="deleteCancelled"> {{ $t("No") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </AdminLayout>
</template>

<script>
import AdminLayout from '@/components/global/adminLayout.vue';
import axios from 'axios';

export default {
  components: { AdminLayout },

  data() {
    return {
      searchPoduct:'',
      selectedProduct:'',
      seller_id:'',
      search: [],
      deleteConfirmation: false,
      loading: false,
      deleteId:null,
      data: [],
      seller:[],
      selectedseller:null,
      
    };
  },
  watch: {
  selectedseller: {
    handler: 'getProducts', // Call getProducts method when selectedseller changes
    immediate: true // Call the handler immediately when the component is created
  }
},
  computed:{
    filtredProducts : function (){
      return this.data.filter((product)=> {
        return product.name.match(this.searchPoduct)
      });
    }
  },
  created(){
    this.getProducts();
    this.getSeller ();
  },
  methods: {
    goToEdit(id) {
    this.$router.push({ name: 'productInfo', params: { id: id } });
  },
  async getSeller (){ 
      try {
        let page = 1;
    let hasNextPage = true;
    while (hasNextPage) {
    const res = await axios.get(`/sellers?page=${page}`);
    const seller = res.data.data;

    // If the sub-categories array is empty or null, there are no more pages
    if (!seller || seller.length === 0) {
      hasNextPage = false;
    } else {
      this.seller.push(...seller); // Add the fetched sub-categories to the existing array
      page++; 
    }
  }
    } catch (error) {
        console.error('Error fetching products:', error);
    }
      
    },
    async getProducts(){ 
      this.data=[];
      this.loading=true;
      try {
        var id =this.selectedseller || 2; 
        let page = 1;
    let hasNextPage = true;
    while (hasNextPage) {
    const res = await axios.get(`/products?page=${page}&seller_id=${id}`);
    const subCategories = res.data.data;

    // If the sub-categories array is empty or null, there are no more pages
    if (!subCategories || subCategories.length === 0) {
      hasNextPage = false;
    } else {
      this.data.push(...subCategories); // Add the fetched sub-categories to the existing array
      page++; // Move to the next page
    }
  }
    } catch (error) {
        console.error('Error fetching products:', error);
    }finally{
      this.loading=false;
    }
      
    },
    deleteItem(id) {
      this.deleteConfirmation = true;
      this.deleteId=id;
    },
    deleteCancelled() {
      this.deleteConfirmation = false;
    },
    async deleteConfirmed(){
      try {
    await axios.delete(`/products/${this.deleteId}`);
    this.getProducts();
    alert('deleted ')
  } catch (error) {
    alert(  error)
  } finally {
    this.deleteConfirmation = false; 
  }
    },
  //   async searchProduct(){
  //   const serch = this.searchPoduct.trim();
  //   if (serch.length >= 3) {
  //     const res = await axios.get(`/products/live/search?key=${serch}`);
  //     this.search=res.data.data
  //   } else {
  //     this.search = [];
  //   }
  // },


  }
};
</script>
<style>
.table-row-hover:hover {
  cursor: pointer;
}
</style>