<template>
  <AdminLayout>
    <div class="d-flex justify-space-between mt-5" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > {{ $t("attributes") }}</p>
      <v-btn  class="mr-4 mx-4 " :text="$t('BACK')"  width="150" rounded color="black" @click="back()"></v-btn>

    </div>
    <v-container> 
      <v-col class="mt-5">
      <error v-if="error"  :error="error"></error>

    </v-col>
      <v-row class=" d-flex flex-column align-center justify-center" >
        <v-col cols="7">
          <div class="d-flex flex-column  justify-center  mr-9">
            <v-form ref="form">
              <v-text-field v-model="name_en" :label="$t('Enter English attribute')"  :rules="[v => !!v || 'name is required']"></v-text-field>
              <v-text-field v-model="name_ar" :label="$t('Enter Arabic attribute')"  :rules="[v => !!v || 'name is required']"></v-text-field>
            </v-form>
            <v-col class=" d-flex justify-center align-center" >
              <v-btn :loading="loading" class="mr-4 " :text="$t('ADD')"  width="200" rounded color="black" @click="AddAttribute()"></v-btn>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-row class=" d-flex justify-center align-center">
      <v-card class="mr-9" :loading="deleteloading" height="fit-content" width="52%">
        <v-table style=" width: 100%;">
        <thead style="background-color: #979797;color: black;">
          <tr>
            <th class=" font-weight-bold"> {{ $t("ID") }} </th>
            <th class=" font-weight-bold"> {{ $t("Name") }}</th>
            <th class=" font-weight-bold"> {{ $t("Actions") }}</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td >
              <v-icon small icon="mdi-delete" color="error" @click="deleteItem(item.id)"></v-icon>
              <v-icon small icon="mdi-file-edit-outline" color="gray" @click="editDialog(item.id)"></v-icon>
            </td>
          </tr>
        </tbody>
      </v-table>
      </v-card>
    </v-row>
  </AdminLayout>
    <!-- edit  dealog ---------------- -->
    <v-dialog v-model="editAttribute" max-width="700">
  <v-card :loading="editloading">
    <v-card-title class="text-center font-weight-bold">EDIT ATTRIBUTE</v-card-title>
    <v-row class=" d-flex flex-column align-center justify-center" >
        <v-col cols="7">
          <div class="d-flex flex-column  justify-center  mr-9">
            <v-text-field v-model="name_en" label="Enter English attribute"></v-text-field>
            <v-text-field v-model="name_ar" label="Enter Arabic Category"></v-text-field>
            <v-col class=" d-flex justify-center align-center" >
              <v-btn :loading="loading" class="mr-4 " :text="$t('ADD ')"  width="200" rounded color="black" @click="editAttr()"></v-btn>
            </v-col>
          </div>
        </v-col>
      </v-row>
  </v-card>
</v-dialog>
  </template>
  <script>
  import AdminLayout from '@/components/global/adminLayout.vue'
import axios from 'axios';
  export default {
    components:{AdminLayout},
    data() {
      return {
        name_en:'',
        name_ar:'',
        data:[],
        loading:false,
        deleteloading:false,
        editloading:false,
        editAttribute:false,
        editId:null,
      }
    },
    created() {
      this.getData();
    },
    methods: {
      back(){
        this.$router.back()
      },
    async  editAttr()
    {
      this.editloading=true;
      try {
        const dataform = new FormData();
        dataform.append('name_en', this.name_en);
        dataform.append('name_ar', this.name_ar);
        dataform.append('_method', 'PUT');
const id = this.editId ;
        await axios.post(`/attributes/${id}`, dataform);

    } catch (e) {
        console.error('Error adding product:', e);
    }finally{
      alert('Edited successfully ' );
            this.name_en = '';
            this.name_ar = '';
      this.getData();
            this.editloading=false;
            this.editAttribute=false;
    }
    },
      editDialog(id){
        this.editAttribute=true;
        this.editId=id
      },
    async  deleteItem(id){
      this.deleteloading=true;
        try {
          await axios.delete(`/attributes/${id}`)
          
        } catch (error) {
          alert('attribute has attribute values')
          console.error(error)
        }finally{
          this.getData();
          this.deleteloading=false;
        }
      },
    async  getData(){
      const res = await axios.get(`/attributes?page=1`);
      this.data=res.data.data;
      },
      async AddAttribute() {
        this.loading=true;
    try {
      if (this.$refs.form && await this.$refs.form.validate())
{
  const dataform = new FormData();
  dataform.append('name_en', this.name_en);
  dataform.append('name_ar', this.name_ar);
  await axios.post('/attributes', dataform);
  alert('Added successfully ' );
}
    } catch (e) {
        console.error('Error adding product:', e);
        alert('Error adding product: ' );

    }finally{
            this.name_en = '';
            this.name_ar = '';
      this.getData();
            this.loading=false;
    }
}

    },
  }
  </script>
  
  