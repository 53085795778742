<template>
  <AdminLayout >
    <div class="d-flex justify-space-between mt-5 mb-12" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > 
        {{ $t("Dashboard") }}
      </p>
    </div>
    <v-row class="cards mx-1" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <v-col 
        v-for="(card, index) in data"
        :key="index" 
        sm="6" 
        md="4" 
        lg="3"
        >
        <v-card class="card" height="150">
          <div class="d-flex align-center justify-space-between">
            <div>
              <v-card-subtitle class="mt-4">{{ card.name }}</v-card-subtitle>
              <v-card-title class="text-h4 mt-1">{{ card.number }}</v-card-title>
            </div>
            <v-avatar size="50" class="bg-amber-accent-1">
              <img alt="Icon" :src="icons[index % icons.length].url">
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mt-5 mb-12" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h5 mt-3 mx-3" > 
        {{ $t("Deals details") }}
      </p>
    </div>
    <v-col>
      <v-select v-model="selectedMonth" :items="translatedMonths" :label="$t('Select Month')"  ></v-select>
    </v-col>
    <v-col sm="12"> 
      <v-card>
        <v-card-text>
          <v-table>
            <thead style="background-color: #F5F6FA;"> 
              <tr>
                <th> {{ $t("ID") }}</th>
                <th> {{ $t("Seller Name") }}</th>
                <th> {{ $t("total quantity") }}</th>
                <th> {{ $t("total price") }}</th>
                <th> {{ $t("Date-time") }}</th>
                <th> {{ $t("user address") }}</th>
                <th > {{ $t("Status") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orders" :key="item.name">
                <td>{{ item.id }}</td>
                <td>{{ item.seller.name }}</td>
                <td>{{ item.total_quantity }}</td>
                <td>{{ item.total_price }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.user_address.city }}/{{ item.user_address.street }}</td>
                <td>
                  <v-chip class="d-flex justify-center align-center" :style="{ 'background-color': getStatusColor(item.status), width: 'fit-content', 'text-align': 'center' }">{{ $t(item.status) }}</v-chip>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
  </AdminLayout>
</template>

<script>
import AdminLayout from '@/components/global/adminLayout.vue'
//import salesDetails from '@/components/charts/salesDetails.vue'
import axios from 'axios';

export default {
  computed: {
    translatedMonths() {
      return this.months.map(month => this.$t(month));
    }
  },
  components: { AdminLayout },
  data() {
    return {
      selectedMonth: null,
      months: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ],
      data: [
        { name: 'Views', number: 4345 },
        { name: 'Total Orders', number: 43454 },
        { name: 'Total Products', number: 4345 },
        { name: 'Total Suppliers', number: 4345 },
      ],
      icons: [
        { url: require('@/assets/icons/Icon.png'), case: 'box' },
        { url: require('@/assets/icons/Iconchart.png'), case: 'chart' },
        { url: require('@/assets/icons/Iconsoon.png'), case: 'soon' },
        { url: require('@/assets/icons/Iconuser.png'), case: 'person' },
      ],
      
      orders:[

      ]

    }
  },
  created() {
    this.getOrders();
  },
  methods:{
    async getOrders() {
      const res = await axios.get('/orders');
      this.orders = [...res.data.data];
 
    },
    getStatusColor(status) {
    switch (status) {
        case 'pending':
            return '#FFD166'; // Yellow
        case 'delivered':
            return '#06D6A0'; // Green
        case 'failed':
            return '#EF476F'; // Red
        case 'on_the_way':
            return '#118AB2'; // Blue
        case 'returned':
            return '#FF851B'; // Orange
        default:
            return '#ced4da'; // Light Grey
    }
},
  }
}
</script>

<style scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.v-avatar img {
  border-radius: 50%;
}
</style>



                  <!-- Sales details -->
                  <!--
                  <v-row >
                    <v-col>
                      <v-card class="mt-2" style="width: 100%;" >
                          <h1 class="text-h3 ml-4 mt-2" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'"> {{ $t("Sales details") }}</h1>
                        
                        <salesDetails class="mb-4"  style="width: 100%;">  </salesDetails>
                
                      </v-card>
                    </v-col>
                  </v-row>
                  -->

                <!-- Deals details -->