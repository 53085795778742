<template>
  <v-app>
    <v-app-bar app elevation="0" color="transparent">
      <v-app-bar-nav-icon class="d-md-none" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items class="d-none d-md-flex align-center justify-center">
        <router-link 
          v-if="userRole === 'seller'" 
          to="/seller" 
          class="nav-link mx-4 my-2 text-black"
        >
          {{ $t('Dashboard') }}
        </router-link>
        <router-link 
          v-if="userRole === 'admin'" 
          to="/admin" 
          class="nav-link mx-4 my-2 text-black"
        >
          {{ $t('Dashboard') }}
        </router-link>
        <router-link 
          v-if="userRole === ''" 
          to="/login" 
          class="nav-link mx-4 my-2 text-black"
        >
          {{ $t('Dashboard') }}
        </router-link>
        <router-link to="/plans" class="nav-link mx-4 my-2 text-black">
          {{ $t('Plans') }}
        </router-link>
        <router-link to="/about" class="nav-link mx-4 my-2 text-black">
          {{ $t('About us') }}
        </router-link>
        <router-link to="/contact" class="nav-link mx-4 my-2 text-black">
          {{ $t('Contact us') }}
        </router-link>
        <router-link to="/services" class="nav-link mx-4 my-2 text-black">
          {{ $t('Services') }}
        </router-link>
        <span  
          @click="dialog = true" 
          class="nav-link mx-4 my-2 text-black"
          style="cursor: pointer;"
        >
          {{ $t('Language') }}
        </span>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      class="d-md-none align-center justify-center"
      rounded="lg"
      width="75%"

      >
      <v-list>
        <v-list-item
          v-if="userRole === 'seller'"
          to="/seller"
        > 
        <v-list-item-title>{{ $t('Dashboard') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="userRole === 'admin'"
          to="/admin"
        >
        <v-list-item-title>{{ $t('Dashboard') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="userRole === ''"
          to="/login"
        >
          <v-list-item-title>{{ $t('Dashboard') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/plans"
        >
        <v-list-item-title>{{ $t('Plans') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/about"
        >
        <v-list-item-title>{{ $t('About us') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/contact"
        >
        <v-list-item-title>{{ $t('Contact us') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/services"
        >
        <v-list-item-title>{{ $t('Services') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="dialog = true">
        <v-list-item-title>{{ $t('Language') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="dialog" max-width="300">
      <v-card rounded="lg">
        <v-card-title>{{ $t('Select Language') }}</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="locale in $i18n.availableLocales"
              :key="locale"
              @click="changeLocale(locale)"
            >
              <v-list-item-title>{{ locale }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="tonal" text @click="dialog = false">{{ $t('Close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-lazy
      :min-height="200"
      :options="{'threshold':0.5}"
      transition="fade-transition"
      >
      <v-main class="main-background">
        <v-container class="mx-auto pa-md-12 pa-sm-6" fluid>
          <v-responsive
            class="text-center mx-auto mb-6 mb-md-12"
            max-width="700"
          >
            <img src="@/assets/images/EboomTitle.png" class="responsive-img" />
            <h1 class="text-md-h4 font-weight-bold mb-4 text-sm-h5 custom-title">
              {{ $t('Transforming Businesses with Eboom') }}
            </h1>

            <p class="text-subtitle-1 text-medium-emphasis">
              {{ $t('With our outstanding team of developers and designers, we strive to deliver the latest technologies and technical solutions that meet the needs and aspirations of our clients in the world of e-commerce.') }}
            </p>
            <v-row class="my-4">
              <v-col>
                <div class="d-flex align-center justify-center pa-4">
                  <v-btn class="mb-2 text-none" color="black" max-height="36" max-width="300" rounded="lg" text="Download The App" variant="flat" width="100%" href='https://play.google.com/store/apps/details?id=com.eboom' target="_blank" />
                </div>
              </v-col>
              <v-col>
                <div class="d-flex align-center justify-center pa-4">
                  <v-btn class="mb-2 text-none" color="black" max-height="36" max-width="300" rounded="lg" text="Sign Up Your Store" variant="flat" width="100%" to="/register"  />
                </div>
              </v-col>
            </v-row>
          </v-responsive>
        </v-container>
      </v-main>
    </v-lazy>
  </v-app>
</template>


<style scoped>
.nav-link {
  text-decoration: none;
  color: inherit;
  font-size: large;
}
.main-background{
  background-image: url('@/assets/images/hero-bg.png');
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 
  height: 100vh;
}
</style>

<script>
export default {
  name: 'HomeView',
  components: {
  }
  ,
  data() {
    return {
      links: [
        { name: 'Dashboard', url: '/seller' },
        { name: 'Plans', url: '/plans' },
        { name: 'About us', url: '/about' },
        { name: 'Contact us', url: '/Contact' },
        { name: 'Login', url: '/login' },
        { name: 'Services', url: '/Services' }
      ],
      dialog: false,
      drawer: false,

    }
  }, 
  computed: {
    userRole() {
      return localStorage.getItem('role') || ''; // Get user's role from local storage
    }
},
methods: {
  shouldRenderDashboardLink() {
      // Determine if the dashboard link should be rendered based on the user's role
      return this.userRole === 'admin' || this.userRole === 'seller' || !this.userRole;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.dialog = false; // Close the dialog after selection
    }
},

}
</script>




