<template>
  <v-alert
      type="error"
      
      closable
    >
    {{ error }}</v-alert>
  </template>
  <script>
  
  export default{
    name:"errorView",
    props:[ 'error']
  }
  </script>