<template>

  <AdminLayout>

    <div class="d-flex justify-space-between mt-5 mb-12" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > 
        {{ $t("Stores") }}
      </p>
    </div>
    <v-col>
      <v-text-field
          v-model="searchPoduct"
          @input="searchProduct"
          :label="$t('Search')" 
          outlined
          placeholder="Type to search..."
        >
      </v-text-field>
    </v-col>
        <v-col sm="12"> 
          <v-card :loading="loading">
            <v-card-text>
              <v-table >
                <thead style="background-color: #F5F6FA; ">
                  <tr>
                    <th> {{ $t("ID") }}</th>
                    <th> {{ $t("Image") }}</th>
                    <th> {{ $t("Name") }}</th>
                    <th> {{ $t("Store name") }}</th>
                    <th> {{ $t("Catigory") }}</th>
                    <th> {{ $t("Date-time") }}</th>
                    <th> {{ $t("Rating") }}</th>
                    <th> {{ $t("Quantity of products") }}</th>
                    <th> {{ $t("Actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filtredStores" :key="item.id"  >
                    <td>{{ item.id }}</td>
                    <td><img class="my-1"  :src="item.image" height="60" width="60"/></td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.store }}</td>
                    <td>{{ item.category.name }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.total_rating }}</td>
                    <td>{{ item.total_quantity }}</td>
                  <td>
                    <v-btn  @click="deleteItem(item.id)"><v-icon color="red" >mdi-delete</v-icon></v-btn>
                    <v-btn @click="gotoStoreInfo(item.id)"><v-icon>mdi-pencil-box-outline</v-icon> </v-btn>
                  </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>





    
    <v-dialog v-model="deleteConfirmation" max-width="500">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="red darken-1" text @click="deleteConfirmed">Yes</v-btn>
          <v-btn color="primary darken-1" text @click="deleteCancelled">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </AdminLayout>
</template>

<script>
import AdminLayout from '@/components/global/adminLayout.vue';
import axios from 'axios';

export default {
  components: { AdminLayout },
  computed:{
    filtredStores : function (){
      return this.data.filter((product)=> {
        return product.name.match(this.searchPoduct)
      });
    }
  },
  data() {
    return {
      loading:false,
      deleteConfirmation: false,
      deleteId:null,
      searchPoduct:'',

      data: [],
    };
  },
  created(){
    this.getData();
  },
  methods: {
    async getData(){ 
      this.loading=true;
      try {
        let page = 1;
    let hasNextPage = true;
    while (hasNextPage) {
    const res = await axios.get(`/sellers?page=${page}`);
    const subCategories = res.data.data;

    // If the sub-categories array is empty or null, there are no more pages
    if (!subCategories || subCategories.length === 0) {
      hasNextPage = false;
    } else {
      this.data.push(...subCategories); // Add the fetched sub-categories to the existing array
      page++; 
    }
  }
    } catch (error) {
        console.error('Error fetching products:', error);
    }finally{
      this.loading=false;
    }
      
    },
    deleteItem(id) {
      this.deleteConfirmation = true;
      this.deleteId=id;
    },
    deleteCancelled() {
      this.deleteConfirmation = false;
    },
    deleteConfirmed() {
      this.loading=true;

  const id = this.deleteId;
  axios.delete(`/sellers/${id}`)
    .then(() => {
      // Remove the deleted item from the data array
      this.data = this.data.filter(item => item.id !== id);
      this.deleteConfirmation = false;
      this.loading=false;

    })
    .catch(error => {
      console.error('Error deleting seller:', error);
      this.loading=false;

    });
}
,
    gotoStoreInfo(id){
      this.$router.push({ name: 'storeInfo', params: { id: id } });
    },
  }
};
</script>
<style>
.table-row-hover:hover {
  cursor: pointer;
}
</style>