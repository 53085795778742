<template>

  <AdminLayout>

    <div class="d-flex justify-space-between mt-5" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > 
        {{ $t("Category") }}
      </p>
    </div>
    <v-col>
      <error v-if="error" :error="error"></error>
    </v-col>
    <v-col>
      <v-btn
        type="submit"
        class="mb-8"
        color="blue"
        size="large"
        variant="tonal"
        :text="$t('Add new catigory')"
        @click="oppenAddCategory()"
        block
        >
      </v-btn>
      <v-btn
        type="submit"
        class="mb-8"
        color="blue"
        size="large"
        variant="tonal"
        :text="$t('Add new attributes')"
        to="/attributes"
        block
        >
      </v-btn>
    </v-col>
    <v-col> 
      <v-select 
        :label="$t('catigories')" 
        v-model="selectedCategory"  
        variant="outlined" 
        :items="allCatigories"
        item-title="name"
        item-value="id"
        width="100%"
        no-data-text="No data available"
        >
      </v-select>
    </v-col>
    <v-col> 
      <v-select 
        v-if="selectedCategory"
        :label="$t('sub catigories')" 
        :loading="selectLoading"
        variant="outlined"
        :items="sub"
        item-title="name"
        width="100%" 
        no-data-text="No data available"
        >
      </v-select>
    </v-col>
    <v-col>
      <v-btn
        type="submit"
        class="mb-8"
        color="blue"
        size="large"
        variant="tonal"
        :text="$t('Add sub catigory')"
        v-if="selectedCategory"
        @click="addSub =true"
        block
        >
      </v-btn>
      <v-btn
        type="submit"
        class="mb-8"
        color="red"
        size="large"
        variant="tonal"
        :text="$t('delete')"
        v-if="selectedCategory"
        @click="deleteCatigory=true"
        block
        >
      </v-btn>
    </v-col>
  </AdminLayout>

  <!-- ---------------- delete catigory dealog ---------------- -->
  <v-dialog v-model="deleteCatigory" max-width="500">
    <v-card :loading="loading" rounded="lg">
      <v-card-title class="text-center font-weight-bold">ARE YOU SURE  ?</v-card-title>
      <div class="d-flex flex-row align-center justify-center" style="gap: 25px;">
        <v-btn
          type="submit"
          class="px-9 mb-4"
          color="red"
          variant="tonal"
          :text="$t('YES')"
          :loading="loading"
          @click="deleteCatigori()"
          rounded
          >
        </v-btn>
        <v-btn
          type="submit"
          class="px-9 mb-4"
          color="blue"
          variant="tonal"
          :text="$t('NO')"
          :loading="loading"
          @click="deleteCatigory=false"
          rounded
          >
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
  <!-- ---------------- add catigory dealog ---------------- -->
  <v-dialog v-model="addCategory" max-width="500">
    <v-card :loading="loading" rounded="lg">
      <v-card-title class="text-center font-weight-bold">ADD CATEGORY</v-card-title>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="6" class="text-center">
            <label for="fileInput" class="avatar-label">
              <v-avatar class="image_hover" size="200">
                <img :src="imageUrl || require('@/assets/icons/Image.png')" alt="Avatar">
              </v-avatar>
            </label>
            <input type="file" id="fileInput" style="display: none;" @change="handleImageUpload">
          </v-col>
        </v-row>
        <v-col>
          <v-text-field 
            v-model="name_en" 
            variant="outlined" 
            label="Enter a Category in English"
            >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field 
            v-model="name_ar" 
            variant="outlined"             
            label="ادخل اسم الفئة بل عربية"
            >
          </v-text-field>
        </v-col>
      </v-container>
      <div class="d-flex flex-row align-center justify-center" style="gap: 25px;">
        <v-btn
          type="submit"
          class="px-9 mb-4"
          color="blue"
          variant="tonal"
          :text="$t('save')"
          :loading="loading"
          @click="saveConfirmed()"
          rounded
          >
        </v-btn>
        <v-btn
          type="submit"
          class="px-9 mb-4"
          color="red"
          variant="tonal"
          :text="$t('ignore')"
          :loading="loading"
          @click="ignore()"
          rounded
          >
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
  <!-- ---------------- add sub catigory dealog ---------------- -->
  <v-dialog v-model="addSub" max-width="500">
    <v-card :loading="loading" rounded="lg">
      <v-card-title class="text-center font-weight-bold">ADD SUB CATEGORY</v-card-title>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="6" class="text-center">
            <label for="fileInput" class="avatar-label">
              <v-avatar size="200">
                <img :src="imageUrl || require('@/assets/icons/Image.png')" alt="Avatar">
            </v-avatar>
            </label>
            <input type="file" id="fileInput" style="display: none;" @change="handleImageUpload">
          </v-col>
        </v-row>
        <v-col>
          <v-text-field 
            v-model="name_en" 
            variant="outlined" 
            label="Enter a Sub Category in English"
            >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field 
            v-model="name_ar" 
            variant="outlined"             
            label="ادخل اسم الفئة المشتقة بل عربية"
            >
          </v-text-field>
        </v-col>
      </v-container>
      <div class="d-flex flex-row align-center justify-center" style="gap: 25px;">
        <v-btn
          type="submit"
          class="px-9 mb-4"
          color="blue"
          variant="tonal"
          :text="$t('save')"
          :loading="loading"
          @click="saveConfirmedsub()"
          rounded
          >
        </v-btn>
        <v-btn
          type="submit"
          class="px-9 mb-4"
          color="red"
          variant="tonal"
          :text="$t('ignore')"
          :loading="loading"
          @click="ignoreSub()"
          rounded
          >
        </v-btn>
      </div>
    </v-card>
  </v-dialog>

</template>

<script>
import AdminLayout from '@/components/global/adminLayout.vue'
import axios from 'axios';
import error from '@/components/global/errorView.vue';

export default {
  components: { AdminLayout,error },
  data() {
    return {
      loading: false,
      selectLoading: false,
      error:'',
      addCategory:false,
      deleteCatigory:false,
      addSub:false,
      imageUrl: '',
      imageFile: null,
      name_ar: '',
      name_en: '',
      home_displaying: false,
      items: [] ,
      allCatigories:[],
      sub:[],
      selectedCategory:null,
      catigoryDetails:null
    }
  },
  created(){
    this.showCatigories();
    this.getDetails()
  },
  
methods: {
  deleteCatigori() {
    this.loading = true;
  axios.delete(`/categories/${this.selectedCategory}`)
    .then(() => {
      this.showCatigories();
      this.deleteCatigory = false;
    })
    .catch(error => {
      alert("  error:  " +"category has products or sellers" );
      this.loading = false;
      this.deleteCatigory = false;
      console.error(error)
    });
    this.showCatigories();
},
  //add catigory function:
  async saveConfirmed(){
    try{
      this.loading= true;
      const formData = new FormData();
    formData.append('name_en', this.name_en);
    formData.append('name_ar', this.name_ar);
    formData.append('type', 'product');
    //formData.append('home_displaying',  this.home_displaying ? 1 : 0);
    formData.append('image', this.imageFile); 
    await axios.post('/categories', formData);
    this.loading= false;
    this.addCategory=false;
    }catch (e) {
    console.error('Error:', e);
this.error=e;
this.loading= false;
  }
  this.showCatigories();
  },
  //add sub catigory function:
  async saveConfirmedsub(){
    try{
      this.loading= true;
      const formData = new FormData();
    formData.append('name_en', this.name_en);
    formData.append('name_ar', this.name_ar);
    formData.append('parent_id', this.selectedCategory);
    formData.append('type', 'product');
    formData.append('image', this.imageFile); 
    await axios.post('/categories', formData);
    this.loading= false;
    this.addSub =false;
    }catch (e) {
    console.error('Error:', e);
this.error=e;
this.loading= false;
  }

  },
  async  showsub(){
    this.sub=[];
    this.selectLoading=true;
    let page = 1;
    let hasNextPage = true;

    while (hasNextPage) {
    const res = await axios.get(`/categories?page=${page}&parent_id=${this.selectedCategory}`);
    const subCategories = res.data.data;

    // If the sub-categories array is empty or null, there are no more pages
    if (!subCategories || subCategories.length === 0) {
      hasNextPage = false;
    } else {
      this.sub.push(...subCategories); // Add the fetched sub-categories to the existing array
      page++; // Move to the next page
    }
  }
this.selectLoading=false;

  },
async  showCatigories(){
  let page =1;
  let hasNextPage = true;
  while (hasNextPage) {
    const res = await axios.get(`/categories?page=${page}`);
    const categories = res.data.data;
    
    // If the categories array is empty or null, there are no more pages
    if (!categories || categories.length === 0) {
      hasNextPage = false;
    } else {
      this.allCatigories.push(...categories); // Add the fetched categories to the existing array
      page++; // Move to the next page
    }
  }
this.showsub();
  },
  async getDetails(){
    try{
      const res = await axios.get(`/categories/${this.selectedCategory}`)
      this.catigoryDetails = res.data.data;
    }catch (e)
    {
alert(e)
    }
  },
  oppenAddCategory(){
    this.addCategory=true;
  },
  ignore(){
    this.addCategory=false;
  },
  ignoreSub(){
    this.addSub=false;
  },

    handleImageUpload(event) {
      const file = event.target.files[0]; // Get the first file from the event
      if (file) {
        this.imageUrl = URL.createObjectURL(file); 
        this.imageFile = file;
      }
    },

},
watch: {
  selectedCategory() {
    this.showsub();
  },
}
}
</script>
<style>
.image_hover:hover{
  cursor:pointer;
}
</style>