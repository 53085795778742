<template>
  <AdminLayout>
    <div class="d-flex justify-space-between mt-5 mb-12" :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'">
      <p class="text-h4 font-weight-bold mt-3 mx-3" > 
        {{ $t("Customers info") }}
      </p>
    </div>
          <v-col>
          <v-text-field
          v-model="searchPoduct"
          @input="searchProduct"
          :label="$t('Search')" 
          outlined
          placeholder="Type to search..."
        ></v-text-field>


          </v-col>

        <v-col sm="12"> 
          <v-card :loading="deleteloading">
            <v-card-text>
              <v-table>
                <thead style="background-color: #F5F6FA; ">
                  <tr>
                    <th> {{ $t("First name") }}</th>
                    <th> {{ $t("Last name") }}</th>
                    <th> {{ $t("Phone") }}</th>
                    <th> {{ $t("Date created") }}</th>
                    <th> {{ $t("Actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filtredCustomers" :key="item.id">
                    <td>{{ item.first_name }}</td>
                    <td>{{ item.last_name }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.created_at }}</td>
                  <td>
                    <v-btn @click="deleteItem(item.id)"><v-icon color="red" >mdi-delete</v-icon></v-btn>
                  </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </v-col>

    <v-dialog v-model="deleteConfirmation" max-width="500">
      <v-card :loading="deleteloading">
        <v-card-title class="headline"> {{ $t("Are you sure you want to delete this item?") }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="deleteloading" color="red darken-1" text @click="deleteConfirmed"> {{ $t("Yes") }}</v-btn>
          <v-btn color="primary darken-1" text @click="deleteCancelled"> {{ $t("No") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </AdminLayout>
</template>

<script>
import AdminLayout from '@/components/global/adminLayout.vue';
import axios from 'axios';

export default {
  components: { AdminLayout },
  computed:{ 
    filtredCustomers : function (){
      return this.data.filter((customer)=> {
        return  (
          customer.phone.toString().includes(this.searchPoduct) || // Search by phone
          customer.first_name.toLowerCase().includes(this.searchPoduct) // Search by name
  );
      });
    }
  },
  data() {
    return {
      deleteConfirmation: false,
      deleteloading: false,
      deleteId:null,
      searchPoduct:'',

      data: [

      ]
    };
  },
  created() {
    this.getCustomers ();
  },
  methods: {
    async getCustomers (){
      this.deleteloading=true;
      const res =await axios.get(`/users?page=1`);
      this.data=res.data.data;
      this.deleteloading=false;
    },
    deleteItem(id) {
      this.deleteConfirmation = true;
      this.deleteId=id;
    },
    deleteCancelled() {
      this.deleteConfirmation = false;
    },
  async  deleteConfirmed(){
    this.deleteloading= true;
    const id = this.deleteId;
  await axios.delete(`/users/${id}`);
  this.deleteloading= false;
  this.deleteConfirmation=false;
  this.getCustomers ();
    }
  }
};
</script>
