<template>
  <v-layout class="rounded rounded-md">
    <!-- left drawer==================== -->
    <v-navigation-drawer
      v-model="drawer"
      width="200"
      class="d-flex flex-column justify-center align-center "
      :dir="$i18n.locale == 'arabic' ? 'rtl' : 'ltr'"
      >

    <img class="ml-0" src="@/assets/images/logo-off.png" height="60" width="60"/>
    <v-divider></v-divider>
    <v-list>
      <v-list-item v-for="(item, index) in menuItems" :key="index" :to="item.route" 
      :class="{ ' bg-black px-9': activeRoute === item.route }">
        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
      </v-list-item>
    </v-list>

  </v-navigation-drawer>
<!-- app bar ================ -->
  <v-app-bar class="d-flex justify-between align-center bg-black" style="height: fit-content;" >

    <v-btn icon @click="drawer = !drawer" class="d-md-none">
        <v-icon>mdi-menu</v-icon>
    </v-btn>
  
  <!-- Center space filler -->
  <div class="flex-grow-1"></div>
  
  <!-- Right side: Rounded icons -->

  <v-menu activator="#act">
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index" @click="menuItemClicked(item)">
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <div>
    <v-btn @click="showDialog = true">{{ $t('Language') }}</v-btn>
    <LanguageDialog v-model="showDialog" />
  </div>

  <v-btn icon class="mx-2">
    <v-icon rounded>
      <img v-if="image" :src="image" height="30" width="30" style="border-radius: 50px;">
      <img v-else src="@/assets/images/account.png" height="30" width="30" style="border-radius: 50px;">
    </v-icon>
  </v-btn>
  <div class=" d-flex flex-column">
    <span class=" mr-2">{{ first_name }}</span>
  </div>
  <v-icon class="mx-2" :title="$t('Logout')" value="logout" @click="logout">mdi-logout-variant</v-icon>

</v-app-bar>


    <v-main >
      <slot ></slot>
    </v-main>
  </v-layout>
</template>

<script>
import notificationSound from '@/assets/sounds/confirmation-tone.wav'; 
import LanguageDialog from '@/components/global/LanguageDialog.vue';

import axios from 'axios';
export default {
  components: {
    LanguageDialog,
  },
  data() {
    return {
      drawer: true,
      image: localStorage.getItem('image'),
      first_name: localStorage.getItem('first_name'),
      email: localStorage.getItem('email'),
      menu: false,
      items: ['seller 1', 'seller 2', 'seller 3'],
      previousItemCount: 0, // Initialize previous item count
      notificationCount: 0,
      notificationSound: new Audio(notificationSound), // Initialize the notification sound
      showDialog: false,
      selectedLanguage: null,
      activeRoute: null,
      menuItems: [
        { title: 'Dashboard', route: '/admin' },
        { title: 'All products', route: '/products' },
        { title: 'Order lists', route: '/orderLists' },
        { title: 'Stores', route: '/stores' },
        { title: 'Admissions', route: '/admissions' },
        { title: 'Plans', route: '/plans-managment' },
        { title: 'Ads', route: '/ads' },
        { title: 'Team', route: '/team' },
        { title: 'Category', route: '/category' },
        { title: 'Customers', route: '/customers' }
      ]
    };
  },
  methods: {
    async logout() {
  try {
    
    localStorage.clear();
    this.$router.push('/login');
    await axios.post('/auth/logout');
    
  } catch (error) {
    // Handle any errors that occur during logout
    if (error.response && error.response.status === 401) {
      console.error('User is not authenticated');
    } else {
      // For other types of errors, log the error to the console
      console.error('Logout error:', error);
    }
  }
}
,
    menuItemClicked(item) {
      // Handle menu item click
      console.log(`Clicked on ${item}`);
              // Play the notification sound
              this.notificationSound.play();
      this.menu = false; // Close the menu after clicking an item
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    changeLocale (locale) {
        this.$vuetify.locale.current = locale
        this.$i18n.locale = locale;
        const localStorageLocale = locale === 'arabic' ? 'ar' : 'en';
        localStorage.setItem('locale', localStorageLocale);  

      },

    //   async fetchNotifications() {
    //   try {
    //     const response = await axios.get('/notifications');
    //     this.items = response.data; // Update the items array with data from the endpoint
        
    //     // Check if new items have been added
    //     if (this.items.length > this.previousItemCount) {
    //       // Increment the notification count
    //       this.notificationCount += this.items.length - this.previousItemCount;
          
    //       // Play the notification sound
    //       this.notificationSound.play();
    //     }
        
    //     // Update the previous item count
    //     this.previousItemCount = this.items.length;
    //   } catch (error) {
    //     console.error('Error fetching notifications:', error);
    //   }
    // },

  },
  created() {
    // Set active route initially
    this.activeRoute = this.$route.path;
    
        // Fetch data from the /notifications endpoint when the component is created
        //this.fetchNotifications();

  },
  watch: {
    // Update active route when route changes
    // eslint-disable-next-line
    $route(to, from) {
      this.activeRoute = to.path;
    }
  }
};
</script>
<style scoped>

.select-container {
  background-color: black;
  width: 100px; 

}
.d-md-none {
  display: none;
}

@media (max-width: 960px) {
  .d-md-none {
    display: inline-flex;
  }
}
/* Style the select element to hide the default dropdown arrow */
.select-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: black;
  color: #ffffff;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

/* Style the select container to add a border and shadow when focused */
.select-container:focus-within {
  border: 2px solid #1976D2;
  box-shadow: 0 0 3px rgba(25, 118, 210, 0.7);
}

/* Style the options to have padding and background color */
.select-container select option {
  padding: 10px;
  background-color: black;
  color: #ffffff;
}


</style> 
