<template>
  <main-Layout>
    <div class="setting" style="height: 700px; padding: 20px;" :dir="$i18n.locale=='arabic'?'rtl':'ltr'">
      <!-- Language Settings -->
      <h1>{{ worked }}</h1>
      <v-card class="mb-4">
        <v-card-title>{{$t('Language Settings')}} :</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <div class="select-container">
                <select
    class="lang"
    v-model="$i18n.locale"
    :items="availableLocales"
    label="Select Language"
    @change="changeLocale($event.target.value)">
    <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
        {{ locale }} 
    </option>
</select>
              </div>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Send Message to Admin -->
      <v-card class="mb-4">
        <v-col>
          <response v-if="res" :res="res">

          </response>
        </v-col>
        <v-card-title> {{$t('Contact Admin')}} :</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="message" label="Type your message"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="6">
              <v-btn @click="sendMessage" color="primary">{{$t('Send Message')}}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      
      <v-card>
        <v-card-title>{{$t('Other Settings')}} (soon)</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2" class="d-flex flex-row" style="gap:10px;">
              <v-switch v-model="notifications" ></v-switch><span class="mt-5">{{ $t('Enable Notifications') }} </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </main-Layout>
</template>

<script>
import MainLayout from "@/components/global/mainLayout.vue";
import axios from "axios";
import response from '@/components/global/response.vue'

export default {
  components: { MainLayout,response },
  computed:{
  },
  data() {
    return {
      res:'',
      selectedLanguage: null,
      email:localStorage.getItem('email'),
      message: "",
      notifications: true,
      
    };
  },
  methods: {
    async sendMessage() {
      try {
        const payload = {
          email: this.email,
          description: this.message
        };

        const res = await axios.post('/contact-us', payload);
        this.res=res.data.data.success;


        this.email = '';
        this.message = '';
      } catch (error) {
        console.error('Error sending message:', error);

      }
    },
    changeLocale (locale) {
        this.$vuetify.locale.current = locale
        this.$i18n.locale = locale;
        const localStorageLocale = locale === 'arabic' ? 'ar' : 'en';
        localStorage.setItem('locale', localStorageLocale);  

      },
  },
};
</script>

<style scoped>
.select-container {
  position: relative;
  width: 200px; /* Adjust width as needed */
}

/* Style the select element to hide the default dropdown arrow */
.select-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

/* Style the select container to add a border and shadow when focused */
.select-container:focus-within {
  border: 2px solid #1976D2;
  box-shadow: 0 0 3px rgba(25, 118, 210, 0.7);
}

/* Style the options to have padding and background color */
.select-container select option {
  padding: 10px;
  background-color: #ffffff;
}


</style> 
